import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import "./LoginForm.scss"
import Button from "../../../components/Button/Button";
import {Link, useNavigate} from "react-router-dom";
import InfoIcon from '../../../assets/images/pink-info-icon.svg'
import YupPassword from "yup-password";
import {accountGet, accountLogin} from "../../../services/UserService";
import {ACCESS_TOKEN, API_BASE_URL} from "../../../utills/constants/api";
import {useDispatch} from "react-redux";
import store from "../../../store/store";
import {setDashboardCategories, setDashboardUser} from "../../../store/actions/DashboardActions";
import {eventGetCategories} from "../../../services/EventService";
import {toast} from "react-toastify";

YupPassword(Yup);

const LoginFormSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'Password must by longer than 6 symbols')
        .minUppercase(1, 'Password must contains at least one uppercase letter')
        .minLowercase(1, 'Password must contains at least one lowercase letter')
        .minNumbers(1, 'Password must contains at least one number')
        .minSymbols(1, 'Password must contains at least one special symbols')
        .required('Password is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
});

const LoginForm = () => {
    const navigate = useNavigate();
    const [passwordInputInitType, setPasswordInputInitType] = useState('password');
    const [loginErrors, setLoginErrors] = useState([]);

    const LoginForm = useFormik({
        validationSchema: LoginFormSchema,
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: values => {
            accountLogin(values.email, values.password)
                .then(async response => {
                    const toastId = toast.loading("Please wait...")
                    if (response.data.success === true) {
                        localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data.data));

                        accountGet().then(async accountResponse => {
                            if (accountResponse.data.success === true) {
                                await eventGetCategories().then((catResponse) => {
                                    if (catResponse.data.success === true) {
                                        store.dispatch(setDashboardCategories(catResponse.data.data.map(el => {
                                            return {label: el.title, value: el.id}
                                        })));
                                    }
                                });

                                if (accountResponse.data.data.isActive) {
                                    toast.update(toastId, {
                                        render: "Login Successful",
                                        type: "success",
                                        isLoading: false,
                                        autoClose: 5000
                                    })
                                    store.dispatch(setDashboardUser(accountResponse.data.data))
                                    navigate('/home')
                                } else {
                                    toast.update(toastId, {
                                        render: "This account is not active",
                                        type: "error",
                                        isLoading: false,
                                        autoClose: 5000
                                    })
                                    localStorage.removeItem(ACCESS_TOKEN)
                                }
                            }
                        })
                    } else {
                        setLoginErrors(response.data.errors)
                    }
                })
        },
    });

    console.log(API_BASE_URL)

    return (
        <div className={"homepage-form"}>
            <h2 className={"homepage-form-title"}>Sign in to your Account</h2>
            <form onSubmit={LoginForm.handleSubmit}>
                {loginErrors.length !== 0 ? (
                    <div className="homepage-form-errors">
                        {loginErrors.map((el) => {
                            return <span key={el.code}>{el.message}</span>
                        })}
                    </div>
                ) : null}
                <div className="homepage-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        onChange={(e) => LoginForm.handleChange(e)}
                        value={LoginForm.values.email}
                    />
                    {LoginForm.errors.email && LoginForm.touched.email ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.email}
                        </div>
                    ) : null}
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        name="password"
                        type={passwordInputInitType}
                        placeholder="Enter your password"
                        onChange={LoginForm.handleChange}
                        value={LoginForm.values.password}
                    />
                    <div
                        onClick={() => setPasswordInputInitType(passwordInputInitType === "text" ? "password" : "text")}
                        className={`dashboard-form-group-input-icon ${passwordInputInitType}`}/>
                    {LoginForm.errors.password && LoginForm.touched.password ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.password}
                        </div>
                    ) : null}
                </div>

                <div className="button-container">
                    <input type={"submit"} className={"button button-primary"} onSubmit={LoginForm.handleSubmit} value={"Submit"} />
                </div>
            </form>

            <Link className={"homepage-form-link"} to={"/reset-password"}>Forgot Password</Link>
            <div className={"homepage-form-link"} onClick={() =>
                window.location.href = 'https://gowzon.com/advertise-with-us'}>Don’t have an account? Register Now</div>
        </div>
    );
};

export default LoginForm;