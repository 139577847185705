import React from 'react';
import TextField from "../../Dashboard/DashbardForm/TextField/TextField";
import Button from "../../Button/Button";
import DashboardFormatTextField from "../../Dashboard/DashbardForm/DashboardFormatTextField/DashboardFormatTextField";
import DashboardSelect from "../../Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import {states} from "../../../utills/constants/general";
import DashboardMultiselect from "../../Dashboard/DashbardForm/DashboardMultiselect/DashboardMultiselect";
import {useSelector} from "react-redux";
import DashboardTextarea from "../../Dashboard/DashbardForm/DashboardTextarea/DashboardTextarea";

const AccountEventDetailsForm = ({AccountEventDetailsForm}) => {
    const {eventCategories} = useSelector((state) => state.dashboardState);

    return (
        <form onSubmit={AccountEventDetailsForm.submitForm} className={"form-container"}>
            <div className="row">
                <div className="dashboard-form-card-title mb-0">
                    Event/Campaign Details
                </div>
                <div className="dashboard-form-info mb-3">
                    We’ll use these details to populate the app with basic information. You can change this information at anytime.                </div>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('name', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.name}
                    errors={AccountEventDetailsForm.errors.name}
                    touched={AccountEventDetailsForm.touched.name}
                    label="Event Location Name"
                    placeholder="Event Location Name"
                    max={50}
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('address', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.address}
                    errors={AccountEventDetailsForm.errors.address}
                    touched={AccountEventDetailsForm.touched.address}
                    label="*Address 1"
                    placeholder="Address 1"
                    className="col-6"/>
                <TextField
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('address2', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.address2}
                    errors={AccountEventDetailsForm.errors.address2}
                    touched={AccountEventDetailsForm.touched.address2}
                    label="Address 2"
                    placeholder="Address 2"
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('city', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.city}
                    errors={AccountEventDetailsForm.errors.city}
                    touched={AccountEventDetailsForm.touched.city}
                    label="*City"
                    placeholder="City"
                    className="col-2"/>
                <DashboardSelect
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('state', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.state}
                    errors={AccountEventDetailsForm.errors.state}
                    touched={AccountEventDetailsForm.touched.state}
                    label="*State"
                    options={states}
                    placeholder="State"
                    className="col-2"/>
                <TextField
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('zip', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.zip}
                    errors={AccountEventDetailsForm.errors.zip}
                    touched={AccountEventDetailsForm.touched.zip}
                    label="*Zip Code"
                    placeholder="Zip Code"
                    className="col-2"/>
                <DashboardFormatTextField
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('eventPhoneNumber', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.eventPhoneNumber}
                    errors={AccountEventDetailsForm.errors.eventPhoneNumber}
                    touched={AccountEventDetailsForm.touched.eventPhoneNumber}
                    mask={"_"}
                    format={"(###)-###-####"}
                    label="Event Phone Number"
                    placeholder="Event Phone Number"
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('eventUrl', e.target.value)}
                    value={AccountEventDetailsForm.initialValues.eventUrl}
                    errors={AccountEventDetailsForm.errors.eventUrl}
                    touched={AccountEventDetailsForm.touched.eventUrl}
                    label="*Event Link"
                    placeholder="Event Link"
                    className="col-6"/>
                <DashboardMultiselect
                    onChange={(option) => AccountEventDetailsForm.setFieldValue('categories', option)}
                    value={AccountEventDetailsForm.initialValues.categories}
                    errors={AccountEventDetailsForm.errors.categories}
                    touched={AccountEventDetailsForm.touched.categories}
                    options={eventCategories}
                    maxOptions={2}
                    placeholder="Select Category"
                    label="*Event Category (Max 2)"
                    className="col-6"/>
            </div>
            <div className="row">
                <DashboardTextarea
                    onChange={(e) => AccountEventDetailsForm.setFieldValue('description', e.slice(0, 1000))}
                    value={AccountEventDetailsForm.initialValues.description}
                    errors={AccountEventDetailsForm.errors.description}
                    touched={AccountEventDetailsForm.touched.description}
                    max={1000}
                    label="*Enter Description"
                    placeholder="Enter Description"
                    className="col-12"/>
            </div>
        </form>
    );
};

export default AccountEventDetailsForm;