import {UPDATE_DASHBOARD_DATA} from "../types";
import {eventGetCategories} from "../../services/EventService";
import store from "../store";
import {setDashboardCategories, setDashboardUser} from "../actions/DashboardActions";
import {accountGet} from "../../services/UserService";
import {ACCESS_TOKEN} from "../../utills/constants/api";

const initialState = {
    eventCategories: [],
    user: {
        email: ''
    },
    userLoading: true,
    selectedUsers: [],
    selectedEvents: [],
    selectedAccount: [],
    accountsList: [],
    accountsFilteredList: [],
    usersList: [],
    usersFilteredList: [],
    accountDetailsEditable: false,
    eventPageCount: 0,
    eventApprovalPageCount: 0,
    eventApprovalType: 0,
    eventSearch: '',
    accountDeleteActionModal: false,
    accountDeletedId: 0,
    accountPageCount: 0,
    userPageCount: 0,
    userSearch: '',
    accountSearch: '',
    eventsApprovalSort: 1,
    accountsSortType: -1,
    accountsSortDir: -1,
    usersSortType: -1,
    usersSortDir: -1,
    eventsSortDir: -1,
    eventsSortType: -1,
    deleteAccountTrigger: false
};

const dashboardInit = async () => {
    if (localStorage.getItem(ACCESS_TOKEN) !== null) {
        await eventGetCategories().then((response) => {
            if (response.data.success === true) {
                store.dispatch(setDashboardCategories(response.data.data.map(el => {
                    return {label: el.title, value: el.id}
                })));
            }
        });
        await accountGet().then(response => {
            if (response.data.success === true) {
                store.dispatch(setDashboardUser(response.data.data))
            }
        })
    }
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_DASHBOARD_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

dashboardInit();

export default dashboardReducer;