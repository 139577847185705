import axios from "axios";
import {ACCESS_TOKEN, API_BASE_URL} from "../utills/constants/api";
import {updateToken} from "./UserService";
import moment from "moment";
import {toast} from "react-toastify";

axios.interceptors.request.use(config => {
    if (localStorage.getItem(ACCESS_TOKEN) !== null) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;

    if(error.response?.status === 401 || error.response?.status === 403) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));

        return updateToken().then(response => {
            if (response.data.success === true) {
                localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data.data));

                axios.defaults.headers.Authorization = 'Bearer ' + response.data.data.token;

                return axios(originalRequest);
            } else {
                localStorage.removeItem(ACCESS_TOKEN);
                toast.error('Your account token has been expired, please log in again.', {delay: 3000});
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            }
        })
    }
    return Promise.reject(error);
});

export const eventCreate = (name, description, address, city, state, eventPhoneNumber, eventTime, imageId, categories, tags, publishingDates, zip, eventUrl, accountId) => {
    const data = {
        name,
        description,
        address,
        city,
        state,
        eventPhoneNumber,
        imageId,
        categories,
        tags,
        publishingDates,
        zip,
        eventUrl,
        accountId
    }

    if (eventTime.trim() !== 'Invalid date') {
        data.eventTime = eventTime;
    }

    return axios.post(API_BASE_URL + '/events/new', {
        ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const eventUpdate = (imageId, id, name, description, address, city, state, eventPhoneNumber, eventTime, categories, tags, publishingDates, zip, eventUrl, externalAdvertiserId, accountId) => {
    return axios.post(API_BASE_URL + '/events/refresh', {
        id, name, imageId, description, address, city, state, eventPhoneNumber, eventTime, categories, tags, publishingDates, zip, eventUrl, externalAdvertiserId, accountId
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const eventDelete = (id) => {
    return axios.post(API_BASE_URL + `/event/${id}/remove`, {
        id
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const eventImageUpload = (FileName, Description, File) => {
    const bodyFormData = new FormData();
    bodyFormData.append('FileName', FileName);
    bodyFormData.append('Description', Description);
    bodyFormData.append('File', File);

    return axios.post(API_BASE_URL + '/images/upload', bodyFormData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'accept': 'accept: text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const eventGetAll = (page, sortDir = -1, sortType = -1) => {
    if (page === undefined || page === null || page === 0) {
        page = 1
    }

    const data = {
        "from": "1900-01-01",
        'page': parseInt(page),
        'pageSize': parseInt(localStorage.getItem('eventPerPage')) || 10
    }

    if (sortDir !== -1) {
        data.sortDir = sortDir;
    }

    if (sortType !== -1) {
        data.sortField = sortType;
    }

    return axios.post(API_BASE_URL + '/events/all', data, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const eventGetAllByDate = (date, to, accountId) => {
    if (to === undefined) {
        to = date
    }

    const data = {
        from: moment(date).hours(0).minutes(0).second(0).format('YYYY-MM-DD'),
        to: moment(to).hours(0).minutes(0).second(0).format('YYYY-MM-DD')
    }

    if (accountId !== undefined) {
        data.accountId = accountId
    }

    return axios.post(API_BASE_URL + '/events/all', {
        ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const eventGet = (id) => {
    return axios.post(API_BASE_URL + `/event/${id}`, {},
        {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const eventGetAllAccount = (accountId, sortDir = -1, sortType = -1) => {
    const data = {
        accountId,
        "from": "1900-01-01",
        "pageSize": 999
    }

    if (sortDir !== -1) {
        data.sortDir = sortDir;
    }

    if (sortType !== -1) {
        data.sortField = sortType;
    }

    return axios.post(API_BASE_URL + `/events/all`, data,
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }
        })
}

export const eventGetAllByStatus = (status, page, sortDir = -1) => {
    if (page === undefined || page === null || page === 0) {
        page = 1
    }

    const data = {
        'page': parseInt(page),
        'approvalStatus': status,
        'pageSize': localStorage.getItem('eventApprovalPerPage') | 10
    }

    if (sortDir !== -1) {
        data.sortDir = sortDir;
        data.sortField = 3;
    }

    return axios.post(API_BASE_URL + `/events/all`, data,
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }
        })
}

export const eventGetAllByStatusAndName = (status, name, page, sortDir = -1) => {
    if (page === undefined || page === null || page === 0) {
        page = 1
    }

    const data = {
        'search': name,
        'page': parseInt(page),
        'approvalStatus': status,
        'pageSize': localStorage.getItem('eventApprovalPerPage') | 10
    }

    if (sortDir !== -1) {
        data.sortDir = sortDir;
        data.sortField = 3;
    }

    return axios.post(API_BASE_URL + `/events/all`, data,
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }
        })
}

export const eventGetCategories = () => {
    return axios.post(API_BASE_URL + `/category/list`, {},
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
    }})
}

export const eventApprove = (eventId) => {
    return axios.post(API_BASE_URL + `/events/${eventId}/approve`, {},
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }})
}

export const eventUnApprove = (eventId) => {
    return axios.post(API_BASE_URL + `/events/${eventId}/un-approve`, {},
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }})
}

export const getEventAnalytic = () => {
    return axios.post(API_BASE_URL + `/custom-analytics/list`, {
            'from': moment(new Date()).hours(0).minutes(0).second(0).subtract(32, 'd').format('YYYY-MM-DD[T]HH:mm:ss'),
            'to': moment(new Date()).hours(0).minutes(0).second(0).subtract(2, 'd').format('YYYY-MM-DD[T]HH:mm:ss'),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }})
}

export const getEventAnalyticByDay = (date) => {
    return axios.post(API_BASE_URL + `/custom-analytics/list`, {
            'from': moment(date).hours(0).minutes(0).second(0).subtract(2, 'd').format('YYYY-MM-DD[T]HH:mm:ss'),
            'to': moment(date).hours(0).minutes(0).second(0).format('YYYY-MM-DD[T]HH:mm:ss'),
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }})
}