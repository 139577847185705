import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {months, years} from "../../../utills/constants/general";
import DashboardAnalyticsTableInfo from "./DashboardAnalyticsTable/DashboardAnalyticsTableInfo";
import {accountGetAnalyticByDate} from "../../../services/AccountService";

const DashboardAnalyticsSelect = ({accountId}) => {
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [account, setAccount] = useState(accountId);

    const handleDataLoad = async (date) => {
        if (accountId !== null) {
            setDataLoading(true)
            accountGetAnalyticByDate(accountId, date).then(res => {
                setData(res.data.data.sort((a, b) => {
                    return new Date(a.publishDate) - new Date(b.publishDate);
                }))
            }).finally(() => setDataLoading(false))
        }
    }

    const handleDateChange = async (date) => {
        setSelectedDay(date)
        await handleDataLoad(date)
    }

    useEffect(async () => {
        await handleDataLoad(selectedDay)
    }, [accountId])

    return (
        <div className="dashboard-card d-flex flex-column align-items-center">
            <h4 className="dashboard-title">Events Calendar</h4>
            <h6 className="dashboard-subtitle mb-5">Select a day from the calendar below to see your daily analytics</h6>
            <div className="d-flex justify-content-between w-100 gap-4">
                <DatePicker
                    inline
                    selected={selectedDay}
                    onChange={(date) => handleDateChange(date)}
                    calendarClassName="dashboard-datepicker"
                    dayClassName={(date) => 'dashboard-datepicker-day'}
                    shouldCloseOnSelect={false}
                    renderCustomHeader={({
                                             date,
                                             changeYear,
                                             changeMonth
                                         }) => (
                        <div className={"dashboard-datepicker-header"}>
                            <select
                                value={months[date.getMonth()]}
                                onChange={({target: {value}}) => {
                                    changeMonth(months.indexOf(value))
                                    setSelectedDay(new Date(selectedDay.getFullYear(), months.indexOf(value), 1))
                                }}>
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={date.getFullYear()}
                                onChange={({target: {value}}) => changeYear(value)}>
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                />
                {dataLoading ? (
                    <div className={"d-flex justify-content-center align-items-center w-100"}>
                        <div className="spinner-grow text-dark" role="status" style={{width: "3em", height: "3em"}}>

                        </div>
                    </div>
                ) : (
                    <DashboardAnalyticsTableInfo data={data}/>
                )}
            </div>
        </div>
    );
};

export default DashboardAnalyticsSelect;