import React, {useEffect, useState} from 'react';
import SearchField from "../../components/Form/SearchField/SearchField";
import DashboardApprovalsTable from "./DashboardApprovalsTable/DashboardApprovalsTable";
import DashboardSelect from "../../components/Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import ToggleField from "../../components/Dashboard/DashbardForm/ToggleField/ToggleField";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {setEventApprovalType, setEventSearch} from "../../store/actions/DashboardActions";
import Button from "../../components/Button/Button";
import {toast} from "react-toastify";
import {eventApprove, eventDelete, eventUnApprove} from "../../services/EventService";
import {Modal} from "react-bootstrap";

const DashboardApprovals = (props) => {
    const [itemsPerPage, setItemsPerPage] = useState(localStorage.getItem('eventApprovalPerPage') | 10);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [action, setAction] = useState('Approve');

    const {eventApprovalPageCount} = useSelector(state => state.dashboardState)
    const {selectedEvents} = useSelector(state => state.dashboardState);
    const {eventApprovalType} = useSelector((state) => state.dashboardState);
    const {eventsApprovalSort} = useSelector((state) => state.dashboardState);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const {page} = useParams();

    return <>
        <Modal style={{zIndex: 40000}} centered={true} show={deleteModalShow}
               onHide={() => setDeleteModalShow(false)}>
            <Modal.Body>
                Are you sure to {action.toLowerCase()} selected {selectedEvents.length === 1 ? 'event' : 'events'}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={async () => {
                    const deleteUserToast = toast.loading('Please wait...')
                    switch (action) {
                        case 'Approve':
                            await Promise.all(
                                selectedEvents.map(async (el) => {
                                    await eventApprove(el)
                                })
                            )
                            break
                        case 'Pause':
                            await Promise.all(
                                selectedEvents.map(async (el) => {
                                    await eventUnApprove(el)
                                })
                            )
                            break
                        case 'Delete':
                            await Promise.all(
                                selectedEvents.map(async (el) => {
                                    await eventDelete(el)
                                })
                            )
                            break
                    }
                    toast.update(deleteUserToast, {
                        render: `Events ${action}d successfully`,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    })
                    navigate('/approvals')
                    setDeleteModalShow(false)
                }}>
                    {action}
                </Button>
            </Modal.Footer>
        </Modal>
        <div className="dashboard-title">
            Event Approval
        </div>
        <div className="dashboard-card">
            <div className="dashboard-card-header">
                <div>
                    <SearchField onChange={(e) => {
                        if (e.target.value === '') {
                            dispatch(setEventSearch(e.target.value))
                        }
                        if (e.keyCode === 13) {
                            dispatch(setEventSearch(e.target.value))
                        }
                    }} onClick={(e) => {
                        dispatch(setEventSearch(e.target.previousElementSibling.value))
                    }
                    } placeholder={"Search Event"}/>
                </div>
            </div>
            <div className="row justify-content-between align-items-center">
                <div className="header-input col-6 d-flex gap-3">
                    <DashboardSelect onChange={(e) => setAction(e.target.value)}
                                     options={['Delete', 'Approve', 'Pause']} value={action}/>

                    {selectedEvents.length > 0 ? (
                        <Button onClick={async () => {
                            setDeleteModalShow(true)
                        }} className={'d-flex align-items-center'} variant={'secondary'}>{action} Selected</Button>
                    ) : null}
                </div>
                <div className="col-4 d-flex justify-content-end">
                    <ToggleField
                        data={[{name: 'Paused', value: 2}, {name: 'Active', value: 1}, {name: 'In Review', value: 0}]}
                        onChange={(e) => {
                            dispatch(setEventApprovalType(e))
                        }}
                        value={parseInt(eventApprovalType)}/>
                </div>
            </div>
            <DashboardApprovalsTable page={page} itemsPerPage={itemsPerPage}/>
        </div>
        <div className="dashboard-pagination pb-3">
            <div className="dashboard-pagination-count">
                <div className="dashboard-pagination-count-container">
                    <select value={itemsPerPage}
                            onChange={(e) => {
                                setItemsPerPage(e.target.value)
                                localStorage.setItem('eventApprovalPerPage', parseInt(e.target.value))
                            }}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                    </select>
                </div>
                <span>Entries per page</span>
            </div>
            <div className="dashboard-pagination-list">
                { location.pathname !== '/approvals' ? (
                    <ReactPaginate
                        nextLabel=""
                        onPageChange={(data) => {
                            navigate(`/approvals/${data.selected + 1}`);
                        }}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={eventApprovalPageCount}
                        initialPage={page - 1}
                        previousLabel=""
                        pageClassName="dashboard-pagination-item"
                        pageLinkClassName="dashboard-pagination-link"
                        previousLinkClassName="dashboard-pagination-link"
                        nextClassName="dashboard-pagination-item-next"
                        previousClassName="dashboard-pagination-item-prev"
                        breakLabel="..."
                        breakClassName="dashboard-pagination-item"
                        breakLinkClassName="dashboard-pagination-link"
                        containerClassName="dashboard-pagination-list-container"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                ) : (
                    <ReactPaginate
                        forcePage={0}
                        nextLabel=""
                        onPageChange={(data) => {
                            navigate(`/approvals/${data.selected + 1}`);
                        }}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={eventApprovalPageCount}
                        initialPage={page - 1}
                        previousLabel=""
                        pageClassName="dashboard-pagination-item"
                        pageLinkClassName="dashboard-pagination-link"
                        previousLinkClassName="dashboard-pagination-link"
                        nextClassName="dashboard-pagination-item-next"
                        previousClassName="dashboard-pagination-item-prev"
                        breakLabel="..."
                        breakClassName="dashboard-pagination-item"
                        breakLinkClassName="dashboard-pagination-link"
                        containerClassName="dashboard-pagination-list-container"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                ) }
            </div>
        </div>
    </>
};

export default DashboardApprovals;