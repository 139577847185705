import React from 'react';
import PasswordChangeForm from "./PasswordChangeForm/PasswordChangeForm";

const PasswordChange = () => {
    return (
        <div className={"container d-flex justify-content-center"}>
            <PasswordChangeForm />
        </div>
    );
};

export default PasswordChange;