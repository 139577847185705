import React from 'react';
import DashboardFormHeader from "../../../components/Dashboard/DashbardForm/DashboardFormHeader/DashboardFormHeader";
import {useFormik} from "formik";
import {AccountFormSchema} from "../../../utills/constants/schemas/account";
import AccountForm from "../../../components/Forms/AccountForm/AccountForm";
import {toast} from "react-toastify";
import {accountCreate} from "../../../services/AccountService";
import {useNavigate} from "react-router";
import {states} from "../../../utills/constants/general";
import Button from "../../../components/Button/Button";

const DashboardAccountsCreate = () => {

    const navigate = useNavigate();

    const AccountCreateForm = useFormik({
        validationSchema: AccountFormSchema,
        initialValues: {
            name: '',
            phoneNumber: '',
            city: '',
            state: states[0],
            address: '',
            address2: '',
            zip: '',
            webSite: '',
        },
        onSubmit: values => {
            const id = toast.loading("Please wait...");

            accountCreate({
                name: values.name,
                phoneNumber: values.phoneNumber,
                address: values.address,
                address2: values.address2,
                city: values.city,
                state: values.state,
                zip: values.zip,
                webSite: values.webSite
            }).then(response => {
                if (response.data.success === true) {
                    toast.update(id, {
                        render: "Account was created successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    })
                    navigate(`/accounts/edit/${response.data.data.id}`)
                } else {
                    toast.update(id, {
                        render: "Account creating was failed",
                        type: "error",
                        isLoading: false,
                        autoClose: 5000
                    })
                }
            }).catch(err => {
                toast.update(id, {
                    render: "Account creating was failed",
                    type: "error",
                    isLoading: false,
                    autoClose: 5000
                })
            })
        },
    });

    return (
        <div className="dashboard-card">
            <div className="dashboard-form">
                <DashboardFormHeader title={'Create Account'} backTo={"/accounts"}/>
            </div>
            <AccountForm AccountForm={AccountCreateForm}/>
            <Button className={'mt-3'} onClick={AccountCreateForm.handleSubmit}>Submit</Button>
        </div>
    );
};

export default DashboardAccountsCreate;