import {createStore, applyMiddleware, combineReducers} from "redux"
import {composeWithDevTools} from "redux-devtools-extension"
import thunk from "redux-thunk"
import dashboardReducer from "./reducers/DashboardReducer";

const rootReducer = combineReducers({
    dashboardState: dashboardReducer
})

const middlewares = [thunk]

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware( ...middlewares ))
)

export default store