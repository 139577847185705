import React, {useEffect, useState} from 'react';
import TextField from "../../../components/Dashboard/DashbardForm/TextField/TextField";
import {useFormik} from "formik";
import Button from "../../../components/Button/Button";
import DashboardFormHeader from "../../../components/Dashboard/DashbardForm/DashboardFormHeader/DashboardFormHeader";
import {Link, useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {employersGetById, employersUpdate} from "../../../services/EmployesService";
import DashboardFormatTextField
    from "../../../components/Dashboard/DashbardForm/DashboardFormatTextField/DashboardFormatTextField";
import {toast} from "react-toastify";
import DashboardSelect from "../../../components/Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import {roles, states} from "../../../utills/constants/general";
import * as Yup from "yup";
import {UserFormSchema} from "../../../utills/constants/schemas/user";
import YupPassword from "yup-password";
import ToggleField from "../../../components/Dashboard/DashbardForm/ToggleField/ToggleField";
import {
    accountsGet,
    changePassword,
    resetPassword,
    userAccounts,
    userAddRole, userGetAccounts,
    userRemoveRole
} from "../../../services/UserService";
import {useSelector} from "react-redux";
import DashboardMultiselect from "../../../components/Dashboard/DashbardForm/DashboardMultiselect/DashboardMultiselect";
import {accountConnect, accountDisconnect} from "../../../services/AccountService";
import {UserFormSchemaEdit} from "../../../utills/constants/schemas/userEdit";

YupPassword(Yup);

const DashboardUsersEdit = () => {
    const [user, setUser] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [connectedAccounts, setConnectedAccounts] = useState([]);
    const {id} = useParams();
    const navigate = useNavigate();

    const [userRoles, setUserRoles] = useState([])

    const { isAdmin } = useSelector(state => state.dashboardState.user)

    useEffect(() => {
        if (!isAdmin){
            toast.error('You have no access to this page')
            navigate('/home')
        }
    })

    const AccountEditForm = useFormik({
        validationSchema: UserFormSchemaEdit,
        enableReinitialize: true,
        initialValues: {
            firstName: user.firstName === null ? '' : user.firstName,
            lastName: user.lastName === null ? '' : user.lastName,
            phoneNumber: user.phoneNumber === null ? '' : user.phoneNumber,
            city: user.city === null ? '' : user.city,
            state: user.state === null ? '' : user.state,
            zip: user.zip === null ? '' : user.zip,
            address: '',
            email: user.email,
            userName: user.userName,
            isActive: user.isActive,
            connectedAccounts: []
        },
        onSubmit: async values => {
            const toastId = toast.loading("Please wait...")

            console.log(values.isActive)
            employersUpdate(id, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                companyId: 1,
                phoneNumber: values.phoneNumber,
                city: values.city,
                state: values.state,
                zip: values.zip,
                userId: id,
                userName: values.userName,
                isActive: (values.isActive === 'true' || values.isActive === true)
            }).then(response => {
                if (response.data.success === true) {
                    toast.update(toastId, {
                        render: "User was updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    })
                    navigate(`/users/edit/${id}`)
                } else {
                    toast.update(toastId, {
                        render: "User updating failed",
                        type: "error",
                        isLoading: false,
                        autoClose: 5000
                    })
                }
            }).catch(() => toast.update(toastId, {
                render: "User updating failed",
                type: "error",
                isLoading: false,
                autoClose: 5000
            }))
        },
    });

    useEffect(() => {
        employersGetById(id).then(async (response) => {
            if (response.data.success === true) {
                setUser(response.data.data);

                await accountsGet().then(accountsResponse => {
                    if (accountsResponse.data.success === true) {
                        setAccounts(accountsResponse.data.data.map(el => {
                            return {value: el.id, label: el.name}
                        }))
                    }
                })

                await userGetAccounts(id).then(userAccountsResponse => {
                    if (userAccountsResponse.data.success === true) {
                        setConnectedAccounts(userAccountsResponse.data.data.map(el => {
                            return {value: el.id, label: el.name}
                        }))
                    }
                })

                if (response.data.data.isAdmin) {
                    setUserRoles((prev) => {
                        return [...prev, roles.find(el => el.label === 'Administrator')]
                    })
                }
                if (response.data.data.isAdvertiser) {
                    setUserRoles((prev) => {
                        return [...prev, roles.find(el => el.label === 'Advertiser')]
                    })
                }
            }
        }).finally(() => {
            setUserLoading(false)
        })
    }, [])

    if (userLoading) {
        return (
            <div className="dashboard-card">
                <div className="dashboard-form">
                    <div className="scheduler-empty mt-5 pt-5 d-flex">
                        <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="dashboard-card">
            <div className="dashboard-form">
                <DashboardFormHeader title={'Edit User'} backTo={"/users"} action={
                    <Button>
                        <Link to={"/account/create"}>Add new User</Link>
                    </Button>
                }/>
                <div className="dashboard-form-card">
                    <div className="dashboard-form-card-title">
                        Details
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('firstName', e.target.value)}
                            value={AccountEditForm.initialValues.firstName}
                            errors={AccountEditForm.errors.firstName}
                            touched={AccountEditForm.touched.firstName}
                            label="First Name"
                            placeholder="First Name"
                            className="col-6"/>
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('lastName', e.target.value)}
                            value={AccountEditForm.initialValues.lastName}
                            errors={AccountEditForm.errors.lastName}
                            touched={AccountEditForm.touched.lastName}
                            label="Last Name"
                            placeholder="Last Name"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <DashboardFormatTextField
                            onChange={(e) => AccountEditForm.setFieldValue('phoneNumber', e.target.value)}
                            value={AccountEditForm.initialValues.phoneNumber}
                            errors={AccountEditForm.errors.phoneNumber}
                            touched={AccountEditForm.touched.phoneNumber}
                            label="Phone Number"
                            placeholder="Phone Number"
                            mask={"_"}
                            format={"(###)-###-####"}
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('city', e.target.value)}
                            value={AccountEditForm.initialValues.city}
                            errors={AccountEditForm.errors.city}
                            touched={AccountEditForm.touched.city}
                            label="City"
                            placeholder="City"
                            className="col-6"/>
                        <DashboardSelect
                            options={states}
                            onChange={(e) => AccountEditForm.setFieldValue('state', e.target.value)}
                            value={AccountEditForm.initialValues.state}
                            errors={AccountEditForm.errors.state}
                            touched={AccountEditForm.touched.state}
                            label="State"
                            placeholder="State"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('zip', e.target.value)}
                            value={AccountEditForm.initialValues.zip}
                            errors={AccountEditForm.errors.zip}
                            touched={AccountEditForm.touched.zip}
                            label="Zip"
                            placeholder="Zip"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('email', e.target.value)}
                            value={AccountEditForm.initialValues.email}
                            errors={AccountEditForm.errors.email}
                            touched={AccountEditForm.touched.email}
                            label="Email"
                            placeholder="aldente@acme.com"
                            className="col-6"/>
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('userName', e.target.value)}
                            value={AccountEditForm.initialValues.userName}
                            errors={AccountEditForm.errors.userName}
                            touched={AccountEditForm.touched.userName}
                            label="Username"
                            placeholder="aldente@acme.com"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <ToggleField
                            name={'active'}
                            data={[{name: 'OFF', value: false}, {name: 'ON', value: true}]}
                            onChange={(e) => AccountEditForm.setFieldValue('isActive', e)}
                            value={AccountEditForm.initialValues.isActive}
                            label={'Active'}/>
                    </div>

                    <Button onClick={AccountEditForm.handleSubmit}>Submit</Button>
                </div>

                <div className="dashboard-form-card mt-4">
                    <div className="row">
                        <div className="dashboard-form-card-title mb-0">
                            User Roles
                        </div>
                    </div>
                    <div className="row mb-0">
                        <DashboardMultiselect
                            onChange={ async (option, action) => {
                                switch (action.action) {
                                    case 'select-option' :
                                        const addRoleToast = toast.loading("Please wait...");
                                        userAddRole(id, action.option.label).then(response => {
                                            if (response.data.success === true) {
                                                toast.update(addRoleToast, {
                                                    render: "User role added successfully",
                                                    type: "success",
                                                    isLoading: false,
                                                    autoClose: 5000
                                                })
                                            }
                                        })
                                        break
                                    case 'remove-value' :
                                        const removeRoleToast = toast.loading("Please wait...");
                                        userRemoveRole(id, action.removedValue.label).then(response => {
                                            if (response.data.success === true) {
                                                toast.update(removeRoleToast, {
                                                    render: "User role removed successfully",
                                                    type: "success",
                                                    isLoading: false,
                                                    autoClose: 5000
                                                })
                                            }
                                        })
                                        break
                                    case 'clear' :
                                        const removeAllRolesToast = toast.loading("Please wait...");
                                        await userRemoveRole(id, 'Administrator')
                                        await userRemoveRole(id, 'Advertiser')

                                        toast.update(removeAllRolesToast, {
                                            render: "User roles removed successfully",
                                            type: "success",
                                            isLoading: false,
                                            autoClose: 5000
                                        })
                                        break
                                }
                            }}
                            options={roles}
                            value={userRoles}
                            placeholder="Roles"
                            label="Roles"
                            inputContainerClassName={"icon icon-star"}
                            className="col-6"/>
                    </div>
                </div>
                <div className="dashboard-form-card mt-4">
                    <div className="row">
                        <div className="dashboard-form-card-title mb-0">
                            Connected Accounts
                        </div>
                    </div>
                    <div className="row">
                        <DashboardMultiselect
                            isClearable={false}
                            onChange={(option, action) => {
                                if (action.action === 'select-option') {
                                    const connectAccountToast = toast.loading("Please wait...");

                                    accountConnect(id, action.option.value).then(response => {
                                        if (response.status === 200) {
                                            toast.update(connectAccountToast, {
                                                render: "User connected successfully",
                                                type: "success",
                                                isLoading: false,
                                                autoClose: 5000
                                            })
                                        } else {
                                            toast.update(connectAccountToast, {
                                                render: "User connecting failed",
                                                type: "error",
                                                isLoading: false,
                                                autoClose: 5000
                                            })
                                        }
                                    }).catch(err => {
                                        toast.update(connectAccountToast, {
                                            render: "User connecting failed",
                                            type: "error",
                                            isLoading: false,
                                            autoClose: 5000
                                        })
                                    })
                                    AccountEditForm.setFieldValue('connectedAccounts', option)
                                } else if (action.action === 'remove-value') {
                                    const connectAccountToast = toast.loading("Please wait...");
                                    accountDisconnect(id, action.removedValue.value).then(response => {
                                        if (response.status === 200) {
                                            toast.update(connectAccountToast, {
                                                render: "User disconnected successfully",
                                                type: "success",
                                                isLoading: false,
                                                autoClose: 5000
                                            })
                                        } else {
                                            toast.update(connectAccountToast, {
                                                render: "User disconnecting failed",
                                                type: "error",
                                                isLoading: false,
                                                autoClose: 5000
                                            })
                                        }
                                    }).catch(err => {
                                        toast.update(connectAccountToast, {
                                            render: "User disconnecting failed",
                                            type: "error",
                                            isLoading: false,
                                            autoClose: 5000
                                        })
                                    })
                                    AccountEditForm.setFieldValue('connectedAccounts', option)
                                }
                            }}
                            options={accounts}
                            value={connectedAccounts}
                            placeholder="Accounts"
                            label="Accounts"
                            inputContainerClassName={"icon icon-star"}
                            className="col-6"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardUsersEdit;