import React, {useState} from 'react';
import TimePicker from "rc-time-picker";
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import "./DashboardTimepicker.scss";

const DashboardTimepicker = ({label, placeholder, value, errors, help, inputContainerClassName, touched, onChange, ...props}) => {
    const [filedValue, setFiledValue] = useState(value === '' ? '' : moment(value));

    return (
        <div className={`dashboard-form-group ${props.className}`}>
            <label>
                {label}
                <div className={`dashboard-form-group-input ${inputContainerClassName}`}>
                    <TimePicker
                        minuteStep={30}
                        showSecond={false}
                        className="dashboard-timepicker"
                        onChange={(e) => {
                            onChange(e)
                            setFiledValue(e)
                        }}
                        value={filedValue}
                        format={'hh:mm A'}
                        popupClassName={"dashboard-timepicker-popup"}
                        use12Hours
                        inputReadOnly
                        placeholder={placeholder}
                        placement={'bottomLeft'}
                    />
                </div>
            </label>
            { help ? (
                <div className='dashboard-form-group-help'>
                    {help}
                </div>
            ) : null }

            {errors && touched ? (
                <div className="dashboard-form-group-errors">
                    {errors}
                </div>
            ) : null}
        </div>
    );
};

export default DashboardTimepicker;