import React, {useEffect, useState} from 'react';
import InfoIcon from "../../../../assets/images/pink-info-icon.svg";
import Button from "../../../../components/Button/Button";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useLocation} from "react-router";
import {userPasswordChange} from "../../../../services/UserService";
import {toast} from "react-toastify";

const PasswordChangeForm = () => {
    const [passwordInputInitType, setPasswordInputInitType] = useState('password');

    const navigate = useNavigate();

    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');
    const userId = new URLSearchParams(search).get('userId');

    useEffect(() => {
        if (code === null || code === undefined || userId === null || userId === undefined) {
            navigate('/sign-in')
        }
    }, [])

    const PasswordResetForm = useFormik({
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .min(6, 'Password must by longer than 6 symbols')
                .minUppercase(1, 'Password must contains at least one uppercase letter')
                .minLowercase(1, 'Password must contains at least one lowercase letter')
                .minNumbers(1, 'Password must contains at least one number')
                .minSymbols(1, 'Password must contains at least one special symbols')
                .required('Password is required'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Repeat Password is required'),
        }),
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: values => {
            const toastId = toast.loading('Please wait...')

            userPasswordChange({
                userId: userId,
                code: code,
                password: values.password,
                confirmPassword: values.confirmPassword
            }).then(response => {
                console.log(response);
                if (response.data.success === true) {
                    toast.update(toastId, {
                        render: "Password changed successfully",
                        isLoading: false,
                        type: 'success',
                        autoClose: 5000
                    })
                    navigate('/sing-in')
                } else {
                    toast.update(toastId, {
                        render: "Something goes wrong",
                        isLoading: false,
                        type: 'error',
                        autoClose: 5000
                    })
                }
            })
        },
    });

    return (
        <div className={"homepage-form"}>
            <h2 className={"homepage-form-title"}>Password Change</h2>
            <form onSubmit={PasswordResetForm.handleSubmit}>
                <div className="homepage-form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        name="password"
                        type={passwordInputInitType}
                        placeholder="Enter your password"
                        onChange={(e) => PasswordResetForm.handleChange(e)}
                        value={PasswordResetForm.values.password}
                    />
                    <div onClick={() => setPasswordInputInitType(passwordInputInitType === "text" ? "password" : "text")}
                         className={`dashboard-form-group-input-icon ${passwordInputInitType}`}/>
                    {PasswordResetForm.errors.password && PasswordResetForm.touched.password ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{PasswordResetForm.errors.password}
                        </div>
                    ) : null}
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="confirmPassword">Repeat Password</label>
                    <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type={passwordInputInitType}
                        placeholder="Enter your repeat password"
                        onChange={(e) => PasswordResetForm.handleChange(e)}
                        value={PasswordResetForm.values.confirmPassword}
                    />
                    <div onClick={() => setPasswordInputInitType(passwordInputInitType === "text" ? "password" : "text")}
                         className={`dashboard-form-group-input-icon ${passwordInputInitType}`}/>
                    {PasswordResetForm.errors.confirmPassword && PasswordResetForm.touched.confirmPassword ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{PasswordResetForm.errors.confirmPassword}
                        </div>
                    ) : null}
                </div>

                <div className="button-container">
                    <input type={"submit"} className={"button button-primary"} onSubmit={PasswordResetForm.handleSubmit} value={"Submit"} />
                </div>
            </form>

            <Link className={"homepage-form-link"} to={"/sign-in"}>Log in</Link>
        </div>
    );
};

export default PasswordChangeForm;