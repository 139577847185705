import React, {forwardRef, useEffect, useState} from 'react';
import {months, years} from "../../../../utills/constants/general";
import DatePicker from "react-datepicker";
import "./DashboardDatepicker.scss";
import moment from "moment";

const DashboardDatepicker = ({ label, disabled, placeholder, className, errors, touched, help, onChange, inputContainerClassName, value}) => {
    const [selectedDays, setSelectedDays] = useState(value);
    const [disabledDays, setDisabledDays] = useState(disabled);

    useEffect(() => {
        setDisabledDays(disabled)
    }, [disabled])

    const handleChange = (date, e) => {
        setSelectedDays((prevState => {
            const dateStr = new Date(date);

            const dateIndex = prevState.findIndex(el => {
                return moment(el).format('YYYY-MM-DD') === moment(dateStr).format('YYYY-MM-DD')
            })

            if (dateIndex !== -1) {
                const res = prevState.filter((el) => {
                    return moment(el).format('YYYY-MM-DD') !== moment(dateStr).format('YYYY-MM-DD')
                })
                onChange([...res])
                return [...res]
            }

            onChange([...prevState, dateStr])
            return [...prevState, dateStr];
        }))
    }

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <label onClick={onClick}>
            {label}
            <div className={`dashboard-form-group-input ${inputContainerClassName}`}>
                <input disabled={true} value={selectedDays.map(el => {
                        return moment(el).format(' MMM D YYYY')
                    })} placeholder={ placeholder }/>
            </div>
        </label>
    ));

    return (
        <div className={`dashboard-form-group ${className}`}>
            <DatePicker
                highlightDates={selectedDays}
                onChange={(value, e) => {
                    handleChange(value, e)
                }}
                calendarClassName="dashboard-datepicker"
                dayClassName={(date) => {
                    if (disabledDays.includes(moment(date).format('YYYY-MM-DD[T]00:00:00'))) {
                        return 'disabled-day'
                    }
                    return "dashboard-datepicker-day"
                }}
                shouldCloseOnSelect={false}
                customInput={<CustomInput />}
                renderCustomHeader={({
                     date,
                     changeYear,
                     changeMonth
                }) => (
                    <div className={"dashboard-datepicker-header"}>
                        <select
                            value={months[date.getMonth()]}
                            onChange={({target: {value}}) =>
                                changeMonth(months.indexOf(value)) }>
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select
                            value={date.getFullYear()}
                            onChange={({target: {value}}) => changeYear(value)} >
                            {years.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            />

            { help ? (
                <div className='dashboard-form-group-help'>
                    {help}
                </div>
            ) : null }

            {errors && touched ? (
                <div className="dashboard-form-group-errors">
                    {errors}
                </div>
            ) : null}
        </div>
    );
};

export default DashboardDatepicker;