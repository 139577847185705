import * as Yup from "yup";

export const URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const AccountFormSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
    address: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
});
