import axios from "axios";
import {ACCESS_TOKEN, API_BASE_URL} from "../utills/constants/api";
import {toast} from "react-toastify";

axios.interceptors.request.use(config => {
    if (localStorage.getItem(ACCESS_TOKEN) !== null) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;

    if(error.response?.status === 401 || error.response?.status === 403) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));

        return updateToken().then(response => {
            if (response.data.success === true) {
                localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data.data));

                axios.defaults.headers.Authorization = 'Bearer ' + response.data.data.token;

                return axios(originalRequest);
            } else {
                localStorage.removeItem(ACCESS_TOKEN);
                toast.error('Your account token has been expired, please log in again.', {delay: 3000});
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            }
        })
    }
    return Promise.reject(error);
});

export const accountSignup = async (email, password) => {
    return await axios.post(API_BASE_URL + '/account/user-register', {
        email, password
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountLogin = async (email, password) => {
    return await axios.post(API_BASE_URL + '/account/signin', {
        email, password
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountGet = async () => {
    const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));

    return await axios.post(API_BASE_URL + '/account/user-info', {}, {
        headers: {
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`
        }
    })
}

export const updateToken = async () => {
    const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));

    return await axios.post(API_BASE_URL + '/account/refresh-token', {
        token, refreshToken
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const resetPassword = async (email, returnUrl) => {
    return await axios.post(API_BASE_URL + '/account/reset-password', {
        email, returnUrl
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const changePassword = async (data) => {
    return await axios.post(API_BASE_URL + '/account/change-password', {
        ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const userAddRole = async (userId, role) => {
    return await axios.post(API_BASE_URL + '/account/add-role', {
        userId, role
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const userRemoveRole = async (userId, role) => {
    return await axios.post(API_BASE_URL + '/account/remove-role', {
        userId, role
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const userDelete = async (userId) => {
    return await axios.post(API_BASE_URL + `/users/${userId}/remove`, {
        userId
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountsGet = async () => {
    return await axios.post(API_BASE_URL + `/gowzon-account/list`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountsGetPagination = async (page, pageCount, userId, search = '') => {
    if (page === undefined || page === null || page === 0) {
        page = 1
    }

    return await axios.post(API_BASE_URL + `/gowzon-account/search`, {
        'search': search,
        'page': page,
        'pageSize': pageCount
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const userGetAccounts = async (userId) => {
    return await axios.post(API_BASE_URL + `/gowzon-account/list?userId=${userId}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const userPasswordReset = async (userEmail) => {
    return await axios.post(API_BASE_URL + `/account/reset-password`, {
        email: userEmail,
        returnUrl: 'http://localhost:3000/sign-in'
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const userPasswordChange = async (data) => {
    return await axios.post(API_BASE_URL + `/account/change-password`, {
        ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const userLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}

