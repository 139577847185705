import React, {useState} from 'react';
import Table from "../../../../components/Table/Table";
import Button from "../../../../components/Button/Button";
import {Link, useParams} from "react-router-dom";
import ModalImage from "react-modal-image";
import {eventDelete} from "../../../../services/EventService";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {setSelectedEvents} from "../../../../store/actions/DashboardActions";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import DashboardEventsFilter
    from "../../../DashboardEvents/DashboardEvents/DashboardEventsFilter/DashboardEventsFilter";

const DashboardAccountsEditEventsTable = ({data}) => {
    const [deleteId, setDeleteId] = useState(0);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    const dispatch = useDispatch();
    const { selectedEvents } = useSelector(state => state.dashboardState)
    const navigate = useNavigate();
    const {id} = useParams();

    const columns = React.useMemo(() => [
        {
            Header: 'Creative',
            accessor: 'imageUrl',
            disableSortBy: true,
            Cell: ({cell: {value}}) => (
                <ModalImage style={{overlay: {zIndex: 10000}}} showRotate={true} small={value} large={value} className={"dashboard-analytics-table-content-creative"} />
            )
        },{
            Header: 'Company',
            accessor: 'account.name'
        },{
            Header: 'Event',
            accessor: 'name'
        }, {
            Header: 'Status',
            accessor: 'approvalStatus',
            Cell: ({cell: {value}}) => {
                return <>{value === 0 ? <span className={"text-orange"}>In Review</span> : value === 1 ?
                    <span className={"text-success"}>Approved</span> :
                    <span className={"text-danger"}>Paused</span>}</> }
        }, {
            id: 'edit',
            disableSortBy: true,
            accessor: 'id',
            Header: () => (
                <div className={"text-center"}>
                    Edit
                </div>
            ),
            Cell: ({cell: {value}}) => (
                <div className={"text-center"}>
                    <Link to={`/events/edit/${value}?redirectTo=/accounts/edit/${id}?tab=2`}>
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4271 1.75705C12.691 2.0209 12.691 2.43668 12.4271 2.70054L11.1158 4.00383L8.62118 1.50918L9.92447 0.197893C10.1883 -0.0659642 10.6041 -0.0659642 10.868 0.197893L12.4271 1.75705ZM0.625 12V9.50535L7.72513 2.4052L10.2198 4.89985L3.11964 12H0.625Z" fill="#8E8EA9"/>
                        </svg>
                    </Link>
                </div>
            )
        },{
            id: 'delete',
            accessor: 'id',
            disableSortBy: true,
            Header: () => (
                <div className={"text-center"}>
                    Delete
                </div>
            ),
            Cell: ({cell: {value}}) => (
                <div className={"text-center"}  onClick={() => {
                    setDeleteId(value)
                    setDeleteModalShow(true)
                }} >
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.57235 3.07438C1.44866 3.07438 1.35462 3.18554 1.37512 3.30753L2.83594 12H8.83594L10.2968 3.30753C10.3173 3.18554 10.2232 3.07438 10.0995 3.07438H1.57235ZM10.6359 0.991736C10.7464 0.991736 10.8359 1.08128 10.8359 1.19174V1.78347C10.8359 1.89393 10.7464 1.98347 10.6359 1.98347H1.03594C0.92548 1.98347 0.835938 1.89393 0.835938 1.78347V1.19174C0.835938 1.08128 0.925481 0.991736 1.03594 0.991736H3.69144C4.14144 0.991736 4.50694 0.446777 4.50694 0H7.16494C7.16494 0.446777 7.52994 0.991736 7.98044 0.991736H10.6359Z" fill="#8E8EA9"/>
                    </svg>
                </div>
            )
        }
    ], []);

    return (
        <>
            <Modal style={{zIndex: 40000}} centered={true} show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>
                <Modal.Body>
                    Are you sure to delete event
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        const deleteEventToast = toast.loading('Please wait...')
                        eventDelete(deleteId).then(response => {
                            toast.update(deleteEventToast, {
                                render: "Event deleted successfully",
                                type: "success",
                                isLoading: false,
                                autoClose: 5000
                            })
                        }).finally(() => {
                            setDeleteModalShow(false)
                            navigate('/events')
                        })
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <DashboardEventsFilter accountId={id} />
            {selectedEvents.length > 0 ? (
                <Button onClick={async () => {
                    const deleteUserToast = toast.loading('Please wait...')
                    await Promise.all(
                        selectedEvents.map( async (el) => {
                            await eventDelete(el)
                        })
                    )
                    toast.update(deleteUserToast, {
                        render: "Events deleted successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    })
                    navigate('/events')
                }} variant={'light'}>Delete Selected</Button>
            ) : null}
            <Table onSelect={(value) => {
                dispatch(setSelectedEvents(value.map(el => {
                    return el.original.id
                })))
            }} columns={columns} data={data} />
        </>
    );
};

export default DashboardAccountsEditEventsTable;