import React, {useEffect, useState} from 'react';
import AccountEventDetailsForm from "../../../../components/Forms/AccountForm/AccountEventDetailsForm";
import Button from "../../../../components/Button/Button";
import {ConditionalWrapper} from "../../../../components/ConditionalWrapper/ConditionalWrapper";
import {useFormik} from "formik";
import {AccountEventDetailsFormSchema} from "../../../../utills/constants/schemas/accountInfo";
import {accountGetById, accountsUpdateEventDetails} from "../../../../services/AccountService";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {states} from "../../../../utills/constants/general";

const DashboardAccountsEditEventDetails = () => {
    const [editable, setEditable] = useState(false);
    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(true);
    const {id} = useParams();
    const {eventCategories} = useSelector((state) => state.dashboardState);

    const AccountEventDetailsEditForm = useFormik({
        validationSchema: AccountEventDetailsFormSchema,
        enableReinitialize: true,
        initialValues: {
            name: data.defaultEvent?.name === undefined || data.defaultEvent?.name === null ? '' : data.defaultEvent?.name,
            description: data.defaultEvent?.description === undefined || data.defaultEvent?.description === null ? '' : data.defaultEvent?.description,
            address: data.defaultEvent?.address === undefined || data.defaultEvent?.address === null  ? '' : data.defaultEvent?.address,
            address2: data.defaultEvent?.address2 === undefined || data.defaultEvent?.address2 === null  ? '' : data.defaultEvent?.address2,
            city: data.defaultEvent?.city,
            state: data.defaultEvent?.state === undefined ? states[0] : data.defaultEvent?.state,
            zip: data.defaultEvent?.zip,
            eventPhoneNumber: data.defaultEvent?.eventPhoneNumber === undefined || data.defaultEvent?.eventPhoneNumber === null  ? '' : data.defaultEvent?.eventPhoneNumber,
            eventUrl: data.defaultEvent?.eventUrl === undefined || data.defaultEvent?.eventUrl === null  ? '' : data.defaultEvent?.eventUrl,
            categories: data.defaultEvent?.categories === undefined ? [] : data.defaultEvent?.categories?.map(el => {
                return eventCategories.find(category => category.label === el)
            }),
        },
        onSubmit: values => {
            const data = {
                ...values, categories: values.categories.map(el => {
                    return el.value;
                })
            }
            const toastId = toast.loading("Please wait...")

            accountsUpdateEventDetails(id, data).then(response => {
                if (response.data.success === true) {
                    toast.update(toastId, {
                        render: "Event details was updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    })
                } else {
                    toast.update(toastId, {
                        render: "Event details updating failed",
                        type: "error",
                        isLoading: false,
                        autoClose: 5000
                    })
                }
            }).catch(err => {
                toast.update(toastId, {
                    render: "Event details updating failed",
                    type: "error",
                    isLoading: false,
                    autoClose: 5000
                })
            }).finally(() => {
                setEditable(false)
            })
        },
    });

    useEffect(() => {
        accountGetById(id).then((response) => {
            if (response.data.success === true) {
                console.log(response.data.data)
                setData(response.data.data)
            }
        }).finally(() => {
            setDataLoading(false)
        })
    }, [])

    if (dataLoading) {
        return (
            <div className="dashboard-form">
                <div className="scheduler-empty mt-5 pt-5 d-flex">
                    <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={`${!editable ? 'dashboard-disabled' : null}`}>
                <AccountEventDetailsForm AccountEventDetailsForm={AccountEventDetailsEditForm}/>
            </div>

            <div className="d-flex gap-3">
                <Button className={'mt-3'} onClick={() => setEditable(true)}>Edit Information</Button>
                <Button className={'mt-3'} onClick={AccountEventDetailsEditForm.handleSubmit} variant={!editable ? 'primary disabled' : 'primary'}>Save</Button>
            </div>
        </>
    );
};

export default DashboardAccountsEditEventDetails;