import React, {useEffect, useState} from 'react';
import "./DashboardEvents.scss";
import EmptyImage from "../../../assets/images/events-empty-image.svg"
import Button from "../../../components/Button/Button";
import {useNavigate} from "react-router";
import DashboardEventsTable from "./DashboardEventsTable/DashboardEventsTable";
import ReactPaginate from "react-paginate";
import {useParams, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const DashboardEvents = (props) => {
    const [eventsPerPage, setEventPerPage] = useState(localStorage.getItem('eventPerPage') | 10);

    const navigate = useNavigate();
    const {page} = useParams();
    const location = useLocation();
    const {eventPageCount} = useSelector(state => state.dashboardState)
    const {isAdmin} = useSelector(state => state.dashboardState.user)
    const {isAdvertiser} = useSelector(state => state.dashboardState.user)

    return <>
        <DashboardEventsTable eventPerPage={eventsPerPage} page={page}/>
        <div className="dashboard-pagination pb-3">
            <div className="dashboard-pagination-count">
                <div className="dashboard-pagination-count-container">
                    <select value={eventsPerPage}
                            onChange={(e) => {
                                setEventPerPage(e.target.value)
                                localStorage.setItem('eventPerPage', parseInt(e.target.value))
                            }}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                    </select>
                </div>
                <span>Entries per page</span>
            </div>
            <div className="dashboard-pagination-list">
                { location.pathname !== '/events' ? (
                   <ReactPaginate
                        nextLabel=""
                        onPageChange={(data) => {
                            navigate(`/events/${data.selected + 1}`);
                        }}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={eventPageCount}
                        initialPage={page - 1}
                        previousLabel=""
                        pageClassName="dashboard-pagination-item"
                        pageLinkClassName="dashboard-pagination-link"
                        previousLinkClassName="dashboard-pagination-link"
                        nextClassName="dashboard-pagination-item-next"
                        previousClassName="dashboard-pagination-item-prev"
                        breakLabel="..."
                        breakClassName="dashboard-pagination-item"
                        breakLinkClassName="dashboard-pagination-link"
                        containerClassName="dashboard-pagination-list-container"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    /> 
                ) : (
                    <ReactPaginate
                        forcePage={0}
                        nextLabel=""
                        onPageChange={(data) => {
                            navigate(`/events/${data.selected + 1}`);
                        }}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={eventPageCount}
                        initialPage={page - 1}
                        previousLabel=""
                        pageClassName="dashboard-pagination-item"
                        pageLinkClassName="dashboard-pagination-link"
                        previousLinkClassName="dashboard-pagination-link"
                        nextClassName="dashboard-pagination-item-next"
                        previousClassName="dashboard-pagination-item-prev"
                        breakLabel="..."
                        breakClassName="dashboard-pagination-item"
                        breakLinkClassName="dashboard-pagination-link"
                        containerClassName="dashboard-pagination-list-container"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    /> 
                ) }
                
            </div>
        </div>
    </>
};

export default DashboardEvents;