import React, {useEffect, useState} from 'react';
import Table from "../../../components/Table/Table";
import {useDispatch, useSelector} from "react-redux";
import {
    eventApprove,
    eventGetAllByStatus, eventGetAllByStatusAndName,
    eventUnApprove
} from "../../../services/EventService";
import {removeTags} from "../../../utills/helpers/string";
import DashboardMultiselect from "../../../components/Dashboard/DashbardForm/DashboardMultiselect/DashboardMultiselect";
import Button from "../../../components/Button/Button";
import {toast} from "react-toastify";
import {
    setEventApprovalPageCount,
    setEventApprovalType, setEventsApprovalSort,
    setSelectedEvents
} from "../../../store/actions/DashboardActions";
import {useLocation, useNavigate} from "react-router";
import ModalImage from "react-modal-image";
import moment from "moment";
import SortIcon from "../../../assets/images/sort-icon.svg";

const DashboardApprovalsTable = ({page, itemsPerPage}) => {
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);

    const [updated, setUpdated] = useState(false);
    const {eventApprovalType} = useSelector((state) => state.dashboardState);
    const {eventSearch} = useSelector((state) => state.dashboardState);
    const {eventsApprovalSort} = useSelector((state) => state.dashboardState);

    const dispatch = useDispatch();
    const location = useLocation();

    const eventApproveHandle = (eventId, rowId, event) => {
        const toastId = toast.loading("Please wait...")

        eventApprove(eventId).then(response => {
            if (response.data.success === true) {
                setData(state => {
                    const newData = state;
                    newData.at(rowId).approvalStatus = 1;
                    return newData
                })
                setUpdated(state => !state);
                toast.update(toastId, {
                    render: "Event was approved successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 5000
                })
            }
        })
    }

    const eventPause = (eventId, rowId, event) => {
        const toastId = toast.loading("Please wait...")

        eventUnApprove(eventId).then(response => {
            if (response.data.success === true) {
                setData(state => {
                    const newData = state;
                    newData.at(rowId).approvalStatus = 1;
                    return newData
                })
                setUpdated(state => !state);
                toast.update(toastId, {
                    render: "Event was paused successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 5000
                })
            }
        })
    }

    useEffect(() => {
        setDataLoading(true)
        eventGetAllByStatus(parseInt(eventApprovalType), page, eventsApprovalSort).then(response => {
            if (response.data.success === true) {
                setData(response.data.data.items)
                dispatch(setEventApprovalPageCount(response.data.data.pagination.totalPages))
            }
        }).finally(() => setDataLoading(false))
    }, [location, itemsPerPage, updated, eventApprovalType])

    useEffect(() => {
        eventGetAllByStatus(parseInt(eventApprovalType), page, eventsApprovalSort).then(response => {
            if (response.data.success === true) {
                setData(response.data.data.items)
                dispatch(setEventApprovalPageCount(response.data.data.pagination.totalPages))
            }
        }).finally(() => setDataLoading(false))
    }, [eventsApprovalSort])

    useEffect(() => {
        setDataLoading(true)
        eventGetAllByStatusAndName(parseInt(eventApprovalType), eventSearch, page, eventsApprovalSort).then(response => {
            if (response.data.success === true) {
                setData(response.data.data.items)
                dispatch(setEventApprovalPageCount(response.data.data.pagination.totalPages))
            }
        }).finally(() => setDataLoading(false))
    }, [eventSearch])

    const columns = React.useMemo(
        () => [
            {
                Header: () => <div className={"cursor-pointer"} id={'date-sort'} data-sort={eventsApprovalSort === 0} data-sort-desc={eventsApprovalSort === 1} onClick={() => {
                    const sort = document.getElementById('date-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setEventsApprovalSort(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setEventsApprovalSort(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setEventsApprovalSort(0))
                    }
                }}>
                    Run Date

                    { document.getElementById('date-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('date-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'nearPublishDate',
                Cell: ({ cell: { value } }) => {
                    return <strong style={{fontStyle: '13px'}} className="position-absolute top-15">
                        {moment(value).format('MM.DD.YYYY')}
                    </strong>
                },
                disableSortBy: true
            },
            {
                Header: '',
                accessor: 'thubnailUrl',
                Cell: ({ cell: { value } }) => {
                    return <div style={{width: '100px'}}>
                            <ModalImage style={{overlay: {zIndex: 10000}}} showRotate={true} small={value} large={value} className={"position-absolute pe-3 top-15"}/>
                       </div>
                },
                disableSortBy: true
            },
            {
                Header: 'Event Details',
                Cell: ({ row }) => {
                    return (
                        <div style={{width: '400px'}}>
                            <strong>Event Name</strong> <br/>
                            <span className={'opacity-50'}>{row.original.name}</span> <br/><br/>
                            <strong>Event Status</strong> <br/>
                            <span className={'opacity-50'}>
                                {row.original.approvalStatus === 0 ? <span className={"text-orange"}>In Review</span> : row.original.approvalStatus === 1 ?
                                    <span className={"text-success"}>Approved</span> :
                                    <span className={"text-danger"}>Paused</span>}
                            </span> <br/><br/>
                            <strong>Event Description</strong> <br/>
                            <span className={'opacity-50'}>{removeTags(row.original.description)}</span> <br/><br/>
                            <strong>Address</strong> <br/>
                            <span className={'opacity-50'}>{ [row.original.address, row.original.city, row.original.state, row.original.zip].join(', ') }</span> <br/><br/>
                            <strong>Company</strong> <br/>
                            <span className={'opacity-50'}>{ row.original.account.name }</span> <br/><br/>
                            <strong>Event Link</strong> <br/>
                            <span className={'opacity-50'}>{ row.original.eventUrl } | <a target="_blank" href={row.original.eventUrl}>Test Link</a></span> <br/><br/>
                            <strong>Event Phone Number</strong> <br/>
                            <span className={'opacity-50'}>{ row.original.eventPhoneNumber }</span> <br/><br/>
                            <strong>Event Category</strong> <br/>
                            <div className="d-flex flex-wrap gap-1 mt-1">
                                {row.original.categories.map(el => {
                                    return <span className={"dashboard-event-badge"}>{el}</span>
                                })}
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'Status',
                Cell: ({ row }) => {
                    return (
                        <div className="d-flex gap-2 align-items-start h-100">
                            <Button onClick={() => {
                                eventApproveHandle(row.original.id, row.id, row.original)
                            }} variant={'primary'}>
                                Approve
                            </Button>
                            { row.original.approvalStatus !== 2 ? (
                                <Button onClick={() => {
                                    eventPause(row.original.id, row.id, row.original)
                                }} variant={'primary'}>
                                    Pause
                                </Button>
                            ) : null }
                        </div>
                    )
                }
            }
        ],
        [])

    if (dataLoading) {
        return (
            <div className={"data-loader"}>
                <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                </div>
            </div>
        )
    }

    return (
        <Table onSelect={(value) => {
            dispatch(setSelectedEvents(value.map(el => {
                return el.original.id
            })))
        }} columns={columns} data={data}/>
    );
};

export default DashboardApprovalsTable;