import React, {useEffect, useState} from 'react';
import "./DashboardAccounts.scss";
import DashboardAccountsTable from "../DashboardAccountsTable/DashboardAccountsTable";
import SearchField from "../../../components/Form/SearchField/SearchField";
import Button from "../../../components/Button/Button";
import ReactPaginate from "react-paginate";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    setAccountDeleteActionModal,
    setAccountFilteredList, setAccountList, setAccountPageCount, setAccountSearch, setAccountTrigger, setUserSearch
} from "../../../store/actions/DashboardActions";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {accountDelete} from "../../../services/AccountService";
import {accountsGetPagination} from "../../../services/UserService";

const DashboardAccounts = () => {
    const navigate = useNavigate();
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(localStorage.getItem('accountPerPage') | 10);
    const [searchValue, setSearchValue] = useState('');

    const { accountSearch } = useSelector((state) => state.dashboardState)

    const dispatch = useDispatch();

    const {page} = useParams(); 
    const location = useLocation();

    const { accountPageCount } = useSelector(state => state.dashboardState)
    const { isAdmin } = useSelector(state => state.dashboardState.user);
    const { isAdvertiser } = useSelector(state => state.dashboardState.user);
    const { selectedAccount } = useSelector(state => state.dashboardState);
    const { accountDeleteActionModal } = useSelector(state => state.dashboardState);
    const { accountDeletedId } = useSelector(state => state.dashboardState);
    const { deleteAccountTrigger } = useSelector((state) => state.dashboardState)
    const { userId } = useSelector(state => state.dashboardState.user);

    const searchUpdate = (e) => {
        setSearchValue(e.target.value)
    }

    const searchKeyDown = (e) => {
        if ((e.target.value.length === 1 && e.keyCode === 8) || e.target.value.length === 0) {
            dispatch(setAccountSearch(''))
        }

        if (e.key === 'Enter' || e.keyCode === 13) {
            dispatch(setAccountSearch(e.target.value))
        }
    }

    const searchUpdateClick = () => {
        dispatch(setAccountSearch(document.getElementById('account-search').value))
    }

    useEffect(() => {
        if (location.pathname === '/accounts') {
            dispatch(setAccountSearch(''))
            setSearchValue('')
        }
    }, [location])

    return (
        <>
            <Modal style={{zIndex: 40000}} centered={true} show={deleteModalShow}
                   onHide={() => setDeleteModalShow(false)}>
                <Modal.Body>
                    Are you sure to delete selected { selectedAccount.length === 1 ? 'account' : 'accounts' }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={async () => {
                        const deleteUserToast = toast.loading('Please wait...')
                        await Promise.all(
                            selectedAccount.map(async (el) => {
                                await accountDelete(el)
                            })
                        )
                        toast.update(deleteUserToast, {
                            render: `${ selectedAccount.length === 1 ? 'Account' : 'Accounts' } deleted successfully`,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        })
                        navigate('/accounts')
                        setDeleteModalShow(false)
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal style={{zIndex: 40000}} centered={true} show={accountDeleteActionModal}
                   onHide={() => dispatch(setAccountDeleteActionModal(false))}>
                <Modal.Body>
                    Are you sure to delete selected account
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={async () => {
                        const deleteUserToast = toast.loading('Please wait...')
                        accountDelete(accountDeletedId).then(response => {
                            if (response.data.success === true) {
                                accountsGetPagination(page, itemsPerPage, userId, accountSearch).then(response => {
                                    if (response.data.success === true) {
                                        dispatch(setAccountList(response.data.data.items.map(el => {
                                            return {...el, city: el.city.trim()}
                                        })))
                                        dispatch(setAccountPageCount(response.data.data.pagination.totalPages))
                                    }
                                })
                                toast.update(deleteUserToast, {
                                    render: `Account deleted successfully`,
                                    type: "success",
                                    isLoading: false,
                                    autoClose: 5000
                                })
                            }
                        }).catch(() => {
                            toast.update(deleteUserToast, {
                                render: `Account deleting failed`,
                                type: "error",
                                isLoading: false,
                                autoClose: 5000
                            })
                        })
                        navigate('/accounts')
                        dispatch(setAccountDeleteActionModal(false))
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="dashboard-title">
                All Accounts
            </div>
            <div className="dashboard-card">
                <div className="dashboard-card-header">
                    <div>
                        <SearchField onKeyDown={(e) => searchKeyDown(e)} value={searchValue} id={"account-search"} onClick={() => searchUpdateClick()} onChange={(e) => searchUpdate(e)} placeholder={"Search Accounts"} />
                    </div>
                    <div>
                        { isAdmin ? (
                            <Button onClick={() => navigate('/accounts/create')}>
                                Add new Account
                            </Button>
                        ) : null }
                    </div>
                </div>
                {selectedAccount.length > 0 && isAdmin ? (
                    <Button onClick={async () => {
                        setDeleteModalShow(true)
                    }} className={'d-flex align-items-center'} variant={'secondary'}>Delete Selected</Button>
                ) : null}
                <DashboardAccountsTable itemsPerPage={itemsPerPage} />
            </div>
            <div className="dashboard-pagination pb-3">
                <div className="dashboard-pagination-count">
                    <div className="dashboard-pagination-count-container">
                        <select value={itemsPerPage}
                                onChange={(e) => {
                                    setItemsPerPage(parseInt(e.target.value))
                                    localStorage.setItem('accountPerPage', parseInt(e.target.value))
                                }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                        </select>
                    </div>
                    <span>Entries per page</span>
                </div>
                <div className="dashboard-pagination-list">

                    { location.pathname !== '/accounts' ? (
                        <ReactPaginate
                            initialPage={page - 1}
                            nextLabel=""
                            onPageChange={(data) => {
                                navigate(`/accounts/${data.selected+1}`);
                            }}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={accountPageCount}
                            previousLabel=""
                            pageClassName="dashboard-pagination-item"
                            pageLinkClassName="dashboard-pagination-link"
                            previousLinkClassName="dashboard-pagination-link"
                            nextClassName="dashboard-pagination-item-next"
                            previousClassName="dashboard-pagination-item-prev"
                            breakLabel="..."
                            breakClassName="dashboard-pagination-item"
                            breakLinkClassName="dashboard-pagination-link"
                            containerClassName="dashboard-pagination-list-container"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    ) : (
                        <ReactPaginate
                            initialPage={page - 1}
                            forcePage={0}
                            nextLabel=""
                            onPageChange={(data) => {
                                navigate(`/accounts/${data.selected+1}`);
                            }}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={accountPageCount}
                            previousLabel=""
                            pageClassName="dashboard-pagination-item"
                            pageLinkClassName="dashboard-pagination-link"
                            previousLinkClassName="dashboard-pagination-link"
                            nextClassName="dashboard-pagination-item-next"
                            previousClassName="dashboard-pagination-item-prev"
                            breakLabel="..."
                            breakClassName="dashboard-pagination-item"
                            breakLinkClassName="dashboard-pagination-link"
                            containerClassName="dashboard-pagination-list-container"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    ) }

                    
                </div>
            </div>
        </>
    );
};

export default DashboardAccounts;