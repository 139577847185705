import React, {useState} from 'react';
import DashboardEventsTable from "../DashboardEvents/DashboardEvents/DashboardEventsTable/DashboardEventsTable";
import ReactPaginate from "react-paginate";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const DashboardCampaigns = () => {
    const [eventsPerPage, setEventPerPage] = useState(localStorage.getItem('eventPerPage') | 10);

    const navigate = useNavigate();
    const {page} = useParams();
    const {eventPageCount} = useSelector(state => state.dashboardState)

    return <>
        <DashboardEventsTable myCampaing={true} />
    </>
};

export default DashboardCampaigns;