import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {eventGetAllAccount} from "../../../../services/EventService";
import EmptyImage from "../../../../assets/images/events-empty-image.svg";
import Button from "../../../../components/Button/Button";
import {useParams} from "react-router-dom";
import DashboardAccountsEditEventsTable from "./DashboardAccountsEditEventsTable";

const DashboardAccountsEditEvents = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(true);

    const {id} = useParams();

    useEffect(() => {
        eventGetAllAccount(id).then(response => {
            if (response.data.success === true && response.data.data.items?.length > 0) {
                setEvents(response.data.data.items)
            }
        }).finally(() => {
            setEventsLoading(false);
        })
    }, [id])

    if (eventsLoading) {
        return (
            <div style={{height: 400}} className="scheduler-empty">
                <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                </div>
            </div>
        );
    }

    if (events.length !== 0 ) {
        return <DashboardAccountsEditEventsTable data={events} />
    }

    return (
        <div className="scheduler-empty">
            <img src={EmptyImage} alt="Empty Image"/>
            <p>
                There are no events created. <br/>
                Let's make your first one!
            </p>
            <Button onClick={() => navigate('/events/create')}>
                Create Event
            </Button>
        </div>
    );
};

export default DashboardAccountsEditEvents;