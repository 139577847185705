import React, {useEffect, useRef, useState} from 'react';
import SearchField from "../../../components/Form/SearchField/SearchField";
import Button from "../../../components/Button/Button";
import ReactPaginate from "react-paginate";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import DashboardUsersTable from "./DashboardUsersTable/DashboardUserTable";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {userDelete} from "../../../services/UserService";
import {setAccountSearch, setUserSearch} from "../../../store/actions/DashboardActions";

const DashboardUsers = () => {
    const [itemsPerPage, setItemsPerPage] = useState(localStorage.getItem('userPerPage') || 10);
    const [searchValue, setSearchValue] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { page } = useParams();
    const { isAdmin } = useSelector(state => state.dashboardState.user)
    const { selectedUsers } = useSelector(state => state.dashboardState)
    const { userSearch } = useSelector(state => state.dashboardState)
    const { userPageCount } = useSelector(state => state.dashboardState)

    const searchUpdate = (e) => {
        setSearchValue(e.target.value)
    }

    const searchKeyDown = (e) => {
        if ((e.target.value.length === 1 && e.keyCode === 8) || e.target.value.length === 0) {
            dispatch(setUserSearch(''))
        }

        if (e.key === 'Enter' || e.keyCode === 13) {
            dispatch(setUserSearch(e.target.value))
        }
    }

    const searchUpdateClick = () => {
        dispatch(setUserSearch(document.getElementById('user-search').value))
    }

    useEffect(() => {
        if (!isAdmin){
            toast.error('You have no access to this page')
            navigate('/home')
        }
    }, [])

    useEffect(() => {
        if (location.pathname === '/users') {
            dispatch(setUserSearch(''))
            setSearchValue('')
        }
    }, [location])

    return (
        <>
            <div className="dashboard-title">
                Users
            </div>
            <div className="dashboard-card">
                <div className="dashboard-card-header">
                    <div className={"d-flex gap-4"}>
                        <SearchField onKeyDown={(e) => searchKeyDown(e)} value={searchValue} onClick={() => searchUpdateClick()} onChange={(e) => searchUpdate(e)} placeholder={"Search Users"} id={"user-search"} />
                        {selectedUsers.length > 0 ? (
                            <Button onClick={async () => {
                                const deleteUserToast = toast.loading('Please wait...')
                                await Promise.all(
                                    selectedUsers.map( async (el) => {
                                        await userDelete(el)
                                    })
                                )
                                toast.update(deleteUserToast, {
                                    render: "Users deleted successfully",
                                    type: "success",
                                    isLoading: false,
                                    autoClose: 5000
                                })
                                navigate('/users')
                            }} variant={'light'}>Delete Selected</Button>
                        ) : null}
                    </div>
                    <div>
                        <Button onClick={() => navigate('/users/create')}>
                            Add new User
                        </Button>
                    </div>
                </div>
                <DashboardUsersTable itemsPerPage={itemsPerPage} />
            </div>
            <div className="dashboard-pagination pb-3">
                <div className="dashboard-pagination-count">
                    <div className="dashboard-pagination-count-container">
                        <select value={itemsPerPage} onChange={(e) => {
                            setItemsPerPage(e.target.value)
                            localStorage.setItem('userPerPage', parseInt(e.target.value))
                        }}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                        </select>
                    </div>
                    <span>Entries per page</span>
                </div>
                <div className="dashboard-pagination-list">
                    { location.pathname !== '/users' ? (
                         <ReactPaginate
                            nextLabel=""
                            onPageChange={(data) => {
                                navigate(`/users/${data.selected+1}`);
                            }}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={userPageCount}
                            initialPage={page-1}
                            previousLabel=""
                            pageClassName="dashboard-pagination-item"
                            pageLinkClassName="dashboard-pagination-link"
                            previousLinkClassName="dashboard-pagination-link"
                            nextClassName="dashboard-pagination-item-next"
                            previousClassName="dashboard-pagination-item-prev"
                            breakLabel="..."
                            breakClassName="dashboard-pagination-item"
                            breakLinkClassName="dashboard-pagination-link"
                            containerClassName="dashboard-pagination-list-container"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    ) : (
                        <ReactPaginate
                            forcePage={0}
                            nextLabel=""
                            onPageChange={(data) => {
                                navigate(`/users/${data.selected+1}`);
                            }}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={userPageCount}
                            initialPage={page-1}
                            previousLabel=""
                            pageClassName="dashboard-pagination-item"
                            pageLinkClassName="dashboard-pagination-link"
                            previousLinkClassName="dashboard-pagination-link"
                            nextClassName="dashboard-pagination-item-next"
                            previousClassName="dashboard-pagination-item-prev"
                            breakLabel="..."
                            breakClassName="dashboard-pagination-item"
                            breakLinkClassName="dashboard-pagination-link"
                            containerClassName="dashboard-pagination-list-container"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    ) }
                </div>
            </div>
        </>
    );
};

export default DashboardUsers;