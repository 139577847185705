import React from 'react';
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../DashboardSidebar/DashboardSidebar";
import  "./DashboardLayout.scss"
import {useLocation} from "react-router";
import {useSelector} from "react-redux";

const DashboardLayout = () => {
    const location = useLocation();

    const { userLoading } = useSelector(state => state.dashboardState)

    if (userLoading) {
        return (
            <div className={"page-loader"}>
                <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                </div>
            </div>
        );
    }

    return (
        <div className={"dashboard"}>
            <div className="dashboard-sidebar">
                <DashboardSidebar />
            </div>
            <div className="dashboard-content">
                <Outlet />
            </div>
        </div>
    );
};

export default DashboardLayout;