import {
    Navigate, Outlet
} from 'react-router-dom';
import {ACCESS_TOKEN} from "../../../utills/constants/api";

function PrivateRoute() {
    const isAuthenticated = localStorage.getItem(ACCESS_TOKEN);

    return isAuthenticated ? <Outlet /> : <Navigate to="/sign-in" />;
}

export default PrivateRoute;