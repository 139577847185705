import React, {useEffect, useState} from 'react';
import EditIcon from  "../../../../assets/images/edit-icon.svg"
import DeleteIcon from  "../../../../assets/images/delete-icon.svg"
import {Link, useParams} from "react-router-dom";
import Table from "../../../../components/Table/Table";
import {employersGet} from "../../../../services/EmployesService";
import {Modal, ModalBody} from "react-bootstrap";
import Button from "../../../../components/Button/Button";
import {userDelete} from "../../../../services/UserService";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {
    setAccountPageCount, setAccountsSortDir, setAccountsSortType,
    setSelectedUsers, setUserPageCount,
    setUsersFilteredList,
    setUsersList, setUsersSortDir, setUsersSortType
} from "../../../../store/actions/DashboardActions";
import {useLocation, useNavigate} from "react-router";
import SortIcon from "../../../../assets/images/sort-icon.svg";

const DashboardUsersTable = ({itemsPerPage}) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [deleteId, setDeleteId] = useState(0);
    const [deleteModalShow, setDeleteModalShow] = useState(false);

    const { page } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { userSearch } = useSelector((state) => state.dashboardState)
    const { usersSortDir } = useSelector((state) => state.dashboardState)
    const { usersSortType } = useSelector((state) => state.dashboardState)
    const { usersList } = useSelector((state) => state.dashboardState)

    const columnsIds = ['user-firstname-sort', 'user-lastname-sort', 'user-email-sort', 'user-status-sort', 'user-roles-sort'];

    const columns = React.useMemo(
        () => [
            {
                Header: () => <div className={"cursor-pointer"} id={'user-firstname-sort'} data-sort={usersSortDir === 0} data-sort-desc={usersSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'user-firstname-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('user-firstname-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setUsersSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setUsersSortDir(-1))
                        dispatch(setUsersSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setUsersSortDir(0))
                        dispatch(setUsersSortType(2))
                    }
                }}>
                    First Name

                    { document.getElementById('user-firstname-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('user-firstname-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'firstName',
                Cell: ({ cell: { value }, row }) => {
                    return <span className={'link-dark'}><Link to={`/users/edit/${row.original.userId}`}>{value}</Link></span>
                },
                disableSortBy: true,
                width: 15
            },
            {
                Header: () => <div className={"cursor-pointer"} id={'user-lastname-sort'} data-sort={usersSortDir === 0} data-sort-desc={usersSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'user-lastname-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('user-lastname-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setUsersSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setUsersSortDir(-1))
                        dispatch(setUsersSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setUsersSortDir(0))
                        dispatch(setUsersSortType(3))
                    }
                }}>
                    Last Name

                    { document.getElementById('user-lastname-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('user-lastname-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'lastName',
                Cell: ({ cell: { value }, row }) => {
                    return <span className={'link-dark'}><Link to={`/users/edit/${row.original.userId}`}>{value}</Link></span>
                },
                width: 15,
                disableSortBy: true,
            },
            {
                Header: () => <div className={"cursor-pointer"} id={'user-email-sort'} data-sort={usersSortDir === 0} data-sort-desc={usersSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'user-email-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('user-email-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setUsersSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setUsersSortDir(-1))
                        dispatch(setUsersSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setUsersSortDir(0))
                        dispatch(setUsersSortType(1))
                    }
                }}>
                    Email address

                    { document.getElementById('user-email-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('user-email-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'email',
                width: 15,
                disableSortBy: true
            },
            {
                Header: () => <div className={"cursor-pointer"} id={'user-roles-sort'} data-sort={usersSortDir === 0} data-sort-desc={usersSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'user-roles-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('user-roles-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setUsersSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setUsersSortDir(-1))
                        dispatch(setUsersSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setUsersSortDir(0))
                        dispatch(setUsersSortType(6))
                    }
                }}>
                    Roles

                    { document.getElementById('user-roles-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('user-roles-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'isAdmin',
                width: 10,
                disableSortBy: true,
                Cell: ({row}) => {
                    return <div className={"d-flex flex-wrap gap-1"}>
                        <span className={`dashboard-badge ${!row.original.isAdmin ? 'd-none' : null}`}>{row.original.isAdmin ? 'Admin' : null}</span>
                        <span className={`dashboard-badge ${!row.original.isAdvertiser ? 'd-none' : null}`}>{row.original.isAdvertiser ? 'Advertiser' : null}</span>
                    </div>
                }
            },
            {
                Header: 'Contact',
                accessor: 'phoneNumber',
                width: 15,
                disableSortBy: true
            },
            {
                Header: () => <div className={"cursor-pointer"} id={'user-status-sort'} data-sort={usersSortDir === 0} data-sort-desc={usersSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'user-status-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('user-status-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setUsersSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setUsersSortDir(-1))
                        dispatch(setUsersSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setUsersSortDir(0))
                        dispatch(setUsersSortType(5))
                    }
                }}>
                    Status

                    { document.getElementById('user-status-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('user-status-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'isActive',
                width: 15,
                Cell: ({ cell: { value } }) =>  <span className={`dashboard-table-cell ${value ? 'active' : 'inactive'}`}>
                    {value ? 'Active' : 'Inactive'}
                </span>,
                disableSortBy: true,
            },
            {
                header: '',
                id: 'click-me-button',
                accessor: 'userId',
                Cell: ({ row }) => {
                    return (
                        <div className={"dashboard-table-controls"}>
                            <Link to={`/users/edit/${row.original.userId}`} >
                                <img src={EditIcon} alt=""/>
                            </Link>
                            <div onClick={(e) => {
                                setDeleteId(row.original.userId)
                                setDeleteModalShow(true)
                            }}>
                                <img src={DeleteIcon} alt=""/>
                            </div>
                        </div>
                    )
                },
                width: 10
            }
        ],
        [])

    useEffect(() => {
        setDataLoading(true)
        employersGet(page, itemsPerPage, userSearch, usersSortDir, usersSortType).then(response => {
            if (response.data.success === true) {
                dispatch(setUsersList(response.data.data.items));
                dispatch(setUserPageCount(response.data.data.pagination.totalPages))
            }
        }).finally(() => setDataLoading(false))
    }, [location, itemsPerPage, userSearch])

    useEffect(() => {
        employersGet(page, itemsPerPage, userSearch, usersSortDir, usersSortType).then(response => {
            if (response.data.success === true) {
                dispatch(setUsersList(response.data.data.items));
                dispatch(setUserPageCount(response.data.data.pagination.totalPages))
            }
        }).finally(() => setDataLoading(false))
    }, [usersSortDir, usersSortType])

    if (dataLoading) {
        return (
            <div className={"data-loader"}>
                <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                </div>
            </div>
        )
    }

    return (
        <>
            <Modal style={{zIndex: 40000}} centered={true} show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>
                <Modal.Body>
                    Are you sure to delete user
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        const deleteUserToast = toast.loading('Please wait...')
                        userDelete(deleteId).then(response => {
                            if (response.data.success === true) {
                                toast.update(deleteUserToast, {
                                    render: "User deleted successfully",
                                    type: "success",
                                    isLoading: false,
                                    autoClose: 5000
                                })
                            }
                        }).finally(() => {
                            setDeleteModalShow(false)
                            navigate('/users')
                        })
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <Table onSelect={(value) => {
                dispatch(setSelectedUsers(value.map(el => {
                    return el.original.userId
                })))
            }} data={usersList} columns={columns} selectWidth={5} />
        </>
    );
};

export default DashboardUsersTable;