import React, {useEffect, useState} from 'react';
import Button from "../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {removeTags} from "../../../../utills/helpers/string";

const DashboardEventsFilterItem = ({event}) => {
    const [image, setImage] = useState(event.thubnailUrl);
    const navigate = useNavigate();

    useEffect(() => {
        setImage(event.thubnailUrl)
    }, [event])

    return (
        <div className={"dashboard-events-filter-list-item"}>
            <div className="dashboard-events-filter-list-item-content">
                <div className="dashboard-events-filter-list-item-row">
                    <strong>
                        Event Name:
                    </strong>
                    <span>{event.name}</span>
                </div>

                <div className="dashboard-events-filter-list-item-row pe-2">
                    <strong>
                        Event Description:
                    </strong>
                    <span>{removeTags(event.description)}</span>

                </div>
                <div className="dashboard-events-filter-list-item-row">
                    <strong>
                        Address:
                    </strong>
                    <span>{[event.address, event.city, event.state, event.zip].join(', ')}</span>
                </div>
                <div className="dashboard-events-filter-list-item-row">
                    <strong>
                        Event Link:
                    </strong>
                    <span>{event.eventUrl}</span>
                </div>
                <div className="dashboard-events-filter-list-item-row">
                    <strong>
                        Event Company:
                    </strong>
                    <span>{ event.account?.name }</span>
                </div>
                <div className="dashboard-events-filter-list-item-row">
                    <strong>
                        Event Phone Number:
                    </strong>
                    <span>{event.eventPhoneNumber}</span>
                </div>
                {event.eventTime ? (
                    <div className="dashboard-events-filter-list-item-row">
                        <strong>
                            Event Actual Time:
                        </strong>
                        <span>{ moment(event.eventTime).format('h:mm A') }</span>
                    </div>
                ) : null}
            </div>
            <div className="dashboard-events-filter-list-item-image">

                <img src={image} alt="Event Image"/>

                <div className="dashboard-events-filter-list-item-controls">
                    <Button className={'w-100'} onClick={() => navigate(`/events/edit/${event.id}`)} variant='secondary'>
                        Edit Event
                    </Button>
                </div>
                
            </div>

        </div>
    );
};

export default DashboardEventsFilterItem;