import * as Yup from "yup";

export const URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const AccountEventDetailsFormSchema = Yup.object().shape({
    name: Yup.string().max(50, 'Max length 50 characters'),
    description: Yup.string().required('This filed is required').max(1000, 'Max length 1000 characters'),
    address: Yup.string().required('This filed is required'),
    address2: Yup.string(),
    city: Yup.string().required('This filed is required'),
    state: Yup.string().required('This filed is required'),
    zip: Yup.string().required('This filed is required'),
    eventPhoneNumber: Yup.string(),
    categories: Yup.array().min(1, 'You need to select at least one category'),
});
