import React, {useEffect, useState} from 'react';
import "./SearchField.scss";

const SearchField = ({placeholder, onChange, onClick, value, ...props}) => {
    const [inputValue, setInputValue] = useState(value)

    useEffect(() => {
        setInputValue(value)
    }, [value])

    return (
        <label className={"dashboard-search"}>
            <input value={inputValue} onChange={(e) => onChange(e)} className={"dashboard-search-input"} type="text" placeholder={placeholder} {...props} />
            <span onClick={(e) => onClick(e)} className={"dashboard-search-icon"} />
        </label>
    );
};

export default SearchField;