import React from 'react';
import "./Button.scss"

const Button = ({variant = 'primary', children, styles, className, ...props}) => {
    return (
        <div className={`button button-${variant} ${className}`} {...props}>
            {children}
        </div>
    );
};

export default Button;