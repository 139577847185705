import React, {useEffect, useState} from 'react';
import {ConditionalWrapper} from "../../../ConditionalWrapper/ConditionalWrapper";

const TextField = ({disabled, max, label, placeholder, value, type = 'text', errors, help, inputContainerClassName, touched, onChange, ...props}) => {
    const [inputType, setInputType] = useState(type);
    const [filedValue, setFiledValue] = useState(value);

    useEffect(() => {
        setFiledValue(value)
    }, [value])

    return (
        <ConditionalWrapper condition={disabled} wrapper={children => (
            <div className={"dashboard-disabled"}>{children}</div>
        )}>
        <div className={`dashboard-form-group ${props.className}`}>
            <label>
                { max !== undefined && max !== null ? `${label} | Characters left: ${max-filedValue?.length > 0 ? max-filedValue?.length : 0}` : label}
                <div className={`dashboard-form-group-input ${inputContainerClassName}`}>
                    <input value={filedValue} type={inputType} placeholder={placeholder} onChange={(e) => {
                        setFiledValue(e.target.value)
                        onChange(e)
                    }} {...props}/>

                    {type === 'password' ? (
                        <div onClick={() => setInputType(inputType === "text" ? "password" : "text")}
                             className={`dashboard-form-group-input-icon ${inputType}`}/>
                    ) : null}

                </div>
            </label>
            { help ? (
                <div className='dashboard-form-group-help'>
                    {help}
                </div>
            ) : null }

            {errors && touched ? (
                <div className="dashboard-form-group-errors">
                    {errors}
                </div>
            ) : null}
        </div>
        </ConditionalWrapper>
    );
};

export default TextField;