import React from 'react';
import "./Login.scss";
import {Navigate} from "react-router-dom";
import LoginForm from "../LoginForm/LoginForm";

const Login = () => {

    return (
        <div className={"container d-flex justify-content-center"}>
            <LoginForm />
        </div>
    );
};

export default Login;