import React, {useEffect} from 'react';
import {useTable} from "react-table";
import {useRowSelect} from "react-table";
import "./Table.scss"
import {useSortBy} from "react-table";
import SortIcon from "../../assets/images/sort-icon.svg";

export const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <label className={"dashboard-table-checkbox"}>
                <input type="checkbox" ref={resolvedRef} {...rest} />
                <div className={"dashboard-table-checkbox-dec"} />
            </label>
        )
    }
)

const Table = ({data, columns, selectWidth = 5, onSelect, withSelect = true}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { selectedRowIds },
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        useRowSelect,
        hooks => {
            if (withSelect) {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        width: selectWidth,
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        Cell: ({ row }) => (
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        ),
                    },
                    ...columns,
                ])
            }
        },
    )

    useEffect(() => {
        if (onSelect) {
            onSelect(selectedFlatRows)
        }
    }, [selectedFlatRows])

    return (
        <div>
            <table {...getTableProps()} className={"dashboard-table"}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                                        : <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                                    : null}
                              </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;