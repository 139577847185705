import React from 'react';
import "./DashboardSidebar.scss";
import { NavLink } from "react-router-dom";
import UserAvatar from "../../../assets/images/user-logo.png";
import {userLogout} from "../../../services/UserService";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";

const DashboardSidebar = () => {

    const navigate = useNavigate();
    const {email} = useSelector(state => state.dashboardState.user)
    const {isAdmin} = useSelector(state => state.dashboardState.user)
    const {isAdvertiser} = useSelector(state => state.dashboardState.user)

    return (
        <div className={"sidebar"}>
            <div className="sidebar-user">
                <div className="sidebar-user-info">
                    <div className="sidebar-user-name">
                        {email}
                    </div>
                    <div className="sidebar-user-role">
                        {isAdmin ? 'Admin' : isAdvertiser ? 'Advertiser' : 'User'}
                    </div>
                </div>
            </div>
            <div className="sidebar-navigation">
                <NavLink to={"/accounts"} className={"sidebar-navigation-item"} activeclassname={"active"}>
                    { isAdmin ? 'All Accounts' : 'My Account' }
                </NavLink>

                {isAdvertiser ? (
                    <NavLink to={"/campaigns"} className={"sidebar-navigation-item"} activeclassname={"active"}>
                        My Campaigns
                    </NavLink>
                ) : null}

                {isAdmin ? (
                    <NavLink to={"/users"} className={"sidebar-navigation-item"} activeclassname={"active"}>
                        Employees
                    </NavLink>
                ) : null}

                <NavLink to={"/analytics"} className={"sidebar-navigation-item"} activeclassname={"active"}>
                    Analytics
                </NavLink>

                {isAdmin ? (
                    <NavLink to={"/events"} className={"sidebar-navigation-item"} activeclassname={"active"}>
                        Events
                    </NavLink>
                ) : null}

                {isAdmin ? (
                    <NavLink to={"/approvals"} className={"sidebar-navigation-item ps-3"} activeclassname={"active"}>
                        Approvals
                    </NavLink>
                ) : null}

                <div className={"sidebar-navigation-item logout"}  onClick={() => {
                    userLogout()
                    navigate('/sign-in')
                }}>
                    Logout
                </div>
            </div>
        </div>
    );
};

export default DashboardSidebar;