import React, {useEffect, useState} from 'react';
import Table from "../../../components/Table/Table";
import "./DashboardAccountsTable.scss"
import EditIcon from  "../../../assets/images/edit-icon.svg"
import DeleteIcon from  "../../../assets/images/delete-icon.svg"
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    setAccountDeleteActionModal,
    setAccountDeletedId,
    setAccountList, setAccountPageCount, setAccountsSortDir, setAccountsSortType,
    setSelectedAccount
} from "../../../store/actions/DashboardActions";
import {useLocation} from "react-router";
import {accountsSortBy} from "../../../services/AccountService";
import SortIcon from "../../../assets/images/sort-icon.svg";

const DashboardAccountsTable = ({itemsPerPage}) => {
    const [dataLoading, setDataLoading] = useState(true);

    const dispatch = useDispatch();
    const { page } = useParams();
    const { accountsList } = useSelector((state) => state.dashboardState)
    const { isAdmin } = useSelector(state => state.dashboardState.user);
    const { userId } = useSelector(state => state.dashboardState.user);
    const { accountSearch } = useSelector((state) => state.dashboardState)
    const { accountsSortType } = useSelector((state) => state.dashboardState)
    const { accountsSortDir } = useSelector((state) => state.dashboardState)
    const { deleteAccountTrigger } = useSelector((state) => state.dashboardState)
    const location = useLocation();

    const columnsIds = ['account-name-sort', 'account-city-sort', 'account-state-sort', 'account-status-sort'];

    const columns = React.useMemo(
        () => [
            {
                Header: () => <div className={"cursor-pointer"} id={'account-name-sort'} data-sort={accountsSortDir === 0} data-sort-desc={accountsSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'account-name-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('account-name-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setAccountsSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setAccountsSortDir(-1))
                        dispatch(setAccountsSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setAccountsSortDir(0))
                        dispatch(setAccountsSortType(1))
                    }
                }}>
                    Name

                    { document.getElementById('account-name-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('account-name-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                Cell: ({ cell: { value }, row}) => <span className={'link-dark'}><Link to={`/accounts/edit/${row.original.id}`} ><b>{value}</b></Link></span>,
                accessor: 'name',
                disableSortBy: true
            },
            {
                Header: () => <div className={"cursor-pointer"} id={'account-city-sort'} data-sort={accountsSortDir === 0} data-sort-desc={accountsSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'account-city-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('account-city-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setAccountsSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setAccountsSortDir(-1))
                        dispatch(setAccountsSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setAccountsSortDir(0))
                        dispatch(setAccountsSortType(2))
                    }
                }}>
                    City

                    { document.getElementById('account-city-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('account-city-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'city',
                disableSortBy: true,
                Cell: ({ cell: { value } }) => {
                    return <span>
                        {value.trim()}
                    </span>
                }
            },
            {
                Header: () => <div className={"cursor-pointer"} id={'account-state-sort'} data-sort={accountsSortDir === 0} data-sort-desc={accountsSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'account-state-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('account-state-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setAccountsSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setAccountsSortDir(-1))
                        dispatch(setAccountsSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setAccountsSortDir(0))
                        dispatch(setAccountsSortType(3))
                    }
                }}>
                    State

                    { document.getElementById('account-state-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('account-state-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'state',
                disableSortBy: true
            },
            {
                Header: 'Billing Status',
                accessor: 'billingStatus',
                Cell: ({ cell: { value } }) =>  <span className={`text-${value === 'Paid' ? 'primary' : 'secondary'}`}>
                    {value}
                </span>
            },
            {
                Header: () => <div className={"cursor-pointer"} id={'account-status-sort'} data-sort={accountsSortDir === 0} data-sort-desc={accountsSortDir === 1} onClick={() => {
                    columnsIds.filter(el => el !== 'account-status-sort').forEach(el => {
                        document.getElementById(el).dataset.sort = 'false';
                        document.getElementById(el).dataset.sortDesc = 'false';
                    })
                    const sort = document.getElementById('account-status-sort')
                    let isDataSort = sort.dataset.sort === 'true';
                    let isDataSortDesc = sort.dataset.sortDesc === 'true';

                    if (isDataSort) {
                        sort.dataset.sort = 'false'
                        sort.dataset.sortDesc = 'true'
                        dispatch(setAccountsSortDir(1))
                    } else if (isDataSortDesc) {
                        sort.dataset.sortDesc = 'false'
                        dispatch(setAccountsSortDir(-1))
                        dispatch(setAccountsSortType(-1))
                    } else {
                        sort.dataset.sort = 'true'
                        dispatch(setAccountsSortDir(0))
                        dispatch(setAccountsSortType(4))
                    }
                }}>
                    Status

                    { document.getElementById('account-status-sort')?.dataset.sort === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                    ) : null }

                    { document.getElementById('account-status-sort')?.dataset.sortDesc === 'true' ? (
                        <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                    ) : null }
                </div>,
                accessor: 'isActive',
                Cell: ({ cell: { value } }) =>  <span className={`dashboard-table-cell ${value ? 'active' : 'inactive'}`}>
                    { value ? 'Active' : 'Inactive' }
                </span>,
                disableSortBy: true
            },
            {
                header: '',
                id: 'click-me-button',
                accessor: 'id',
                Cell: ({ cell: { value } }) => (
                    <div className={"dashboard-table-controls"}>
                        <Link to={`/accounts/edit/${value}`} >
                            <img src={EditIcon} alt=""/>
                        </Link>
                        { isAdmin ? (
                            <img onClick={() => {
                                dispatch(setAccountDeletedId(value))
                                dispatch(setAccountDeleteActionModal(true))
                            }} src={DeleteIcon} alt=""/>
                        ) : null }
                    </div>
                ),
            }
        ],
        [])

    useEffect(() => {
        accountsSortBy(page, itemsPerPage, userId, accountSearch, accountsSortType, accountsSortDir).then(response => {
            if (response.data.success === true) {
                dispatch(setAccountList(response.data.data.items.map(el => {
                    return {...el, city: el.city.trim()}
                })))
                dispatch(setAccountPageCount(response.data.data.pagination.totalPages))
            }
        }).finally(() => setDataLoading(false))
    }, [location, itemsPerPage, accountSearch, accountsSortType, accountsSortDir])


    if (dataLoading) {
        return (
            <div className={"data-loader"}>
                <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                </div>
            </div>
        )
    }

    return (
        <Table onSelect={(value) => {
            dispatch(setSelectedAccount(value.map(el => {
                return el.original.id
            })))
        }} data={accountsList} columns={columns} selectWidth={5} withSelect={isAdmin} />
    );
};

export default DashboardAccountsTable;