import React from 'react';
import {useSortBy, useTable} from "react-table";
import SortIcon from "../../../../assets/images/sort-icon.svg";
import moment from "moment";
import {Link} from "react-router-dom";
import ModalImage from "react-modal-image";

const DashboardAnalyticsTableInfo = ({data}) => {
    const columns = React.useMemo(() => [
        {
            Header: 'Event Date',
            accessor: 'publishDate',
            Cell: ({cell: {value}}) => {
                moment.updateLocale(moment.locale(), { invalidDate: '-' })
                return moment(value).format('MM/DD/YYYY')
            }
        },
        {
            Header: '',
            accessor: 'imageUrl',
            Cell: ({cell: {value}}) => (
                <ModalImage style={{overlay: {zIndex: 10000}}} showRotate={true} small={value} large={value}
                            className={"dashboard-analytics-table-content-creative opacity-1"}/>
            )
        },{
            Header: <span className={"text-start"}>Name</span>,
            accessor: 'gowzonEventName',
            Cell: ({cell: {value}}) => <span className={"text-start"}>{value}</span>
        },{
            Header: 'Impression',
            accessor: 'show'
        },{
            Header: 'Clicks',
            accessor: 'detail'
        },{
            Header: 'Shares',
            accessor: 'share'
        },{
            Header: 'Calls',
            accessor: 'phoneCall'
        },{
            Header: 'Website',
            accessor: 'visitWebsite'
        }
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    )

    if (data.length === 0) {
        return <div className={"d-flex justify-content-center align-items-center w-100"}>No data found</div>;
    }

    return (
        <table {...getTableProps()} className={"dashboard-analytics-table-info mt-4"}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                                        : <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                                    : null}
                              </span>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(
                (row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                )
                            })}
                        </tr>
                    )
                }
            )}
            </tbody>
        </table>
    );
};

export default DashboardAnalyticsTableInfo;