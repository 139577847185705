import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export const UserFormSchemaEdit = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    email: Yup.string().email('Invalid email').required('This field is required'),
    userName: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
});