import React, {useEffect, useState} from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const DashboardTextarea = ({label, options, placeholder, value, errors, help, inputContainerClassName, touched, onChange, ...props}) => {
    const [filedValue, setFiledValue] = useState(value);

    useEffect(() => {
        setFiledValue(value)
    }, [value])

    return (
        <div className={`dashboard-form-group ${props.className}`}>
            <label>
                {label}
                <div className={`dashboard-form-group-input ${inputContainerClassName}`}>
                    <select value={filedValue} onChange={(e) => {
                        onChange(e)
                        setFiledValue(e.target.value)
                    }} placeholder={placeholder}>
                        { options.map((el, key) => {
                            return <option key={key} value={el.value === undefined ? el : el.value}>{el.name === undefined ? el : el.name}</option>
                        }) }
                    </select>
                </div>
            </label>
            { help ? (
                <div className='dashboard-form-group-help'>
                    {help}
                </div>
            ) : null }

            {errors && touched ? (
                <div className="dashboard-form-group-errors">
                    {errors}
                </div>
            ) : null}
        </div>
    );
};

export default DashboardTextarea;