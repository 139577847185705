import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import DashboardAnalyticsTable from "./DashboardAnalyticsTable/DashboardAnalyticsTable";
import DashboardSelect from "../../../components/Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import {accountsGet} from "../../../services/UserService";
import {useSelector} from "react-redux";
import {accountGetAnalytic, accountGetAnalyticSummary} from "../../../services/AccountService";
import DashboardAnalyticsSelect from "./DashboardAnalyticsSelect";

const DashboardAnalytics = () => {
    const [accounts, setAccounts] = useState([]);
    const [data, setData] = useState([]);
    const [accountsLoading, setAccountsLoading] = useState(true);
    const [analyticsLoading, setAnalyticsLoading] = useState(true);
    const [accountId, setAccountId] = useState(null);
    const [accountName, setAccountName] = useState('');

    const {userId} = useSelector(state => state.dashboardState.user);

    useEffect(() => {
        accountsGet(userId).then(response => {
            if (response.data.success === true && response.data.data.length > 0) {
                setAccounts(response.data.data.filter(el => el.isActive).map(el => {
                    return {name: el.name, value: el.id, id: el.id}
                }))
                setAccountId(response.data.data.filter(el => el.isActive)[0].id)

                accountGetAnalyticSummary(response.data.data.filter(el => el.isActive)[0].id).then(analyticResponse => {
                    if (analyticResponse.data.success === true) {
                        setData(analyticResponse.data.data.map(el => {
                            return {...el, name: response.data.data.filter(el => el.isActive)[0].name}
                        }));
                    }
                }).finally(() => setAnalyticsLoading(false))
            }
        }).finally(() => setAccountsLoading(false))
    }, [])

    useEffect(() => {
        setAnalyticsLoading(true)
        if (accountId !== null && accountName !== '') {
            accountGetAnalyticSummary(accountId).then(response => {
                if (response.data.success === true) {
                    setData(response.data.data.map(el => {
                        return {...el, name: accountName}
                    }));
                }
            }).finally(() => setAnalyticsLoading(false))
        }
    }, [accountId])

    return (
        <>
            <div className="dashboard-title">
                Analytics
            </div>
            <div className="dashboard-card mb-0" style={{minHeight: "unset"}}>
                <div className="dashboard-card-header mb-0">
                    {accountsLoading ? (
                        <div className="spinner-grow text-dark" role="status" style={{width: "3em", height: "3em"}}>

                        </div>
                    ) : (
                        <DashboardSelect value={accountId} className={'col-3'} options={accounts} onChange={(e) => {
                            setAccountId(parseInt(e.target.value))
                            setAccountName(e.target.options[e.target.selectedIndex].text)
                        }}/>
                    )}
                </div>
            </div>

            { analyticsLoading ? (
                <div className="dashboard-card" style={{minHeight: 0}}>
                    <div className="dashboard-form">
                        <div className="scheduler-empty d-flex">
                            <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <DashboardAnalyticsTable analytics={data}/>
            )}

            <DashboardAnalyticsSelect accountId={accountId} />
        </>
    );
};

export default DashboardAnalytics;