import React, {useEffect, useState} from 'react';
import Button from "../../../components/Button/Button";
import DashboardFormHeader from "../../../components/Dashboard/DashbardForm/DashboardFormHeader/DashboardFormHeader";
import {useParams} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {useFormik} from "formik";
import DashboardAccountsEditBilling from "./DashboardAccountsEditBilling/DashboardAccountsEditBilling";
import {useLocation, useNavigate} from "react-router";
import {AccountFormSchema} from "../../../utills/constants/schemas/account";
import AccountForm from "../../../components/Forms/AccountForm/AccountForm";
import {accountActivate, accountDeactivate, accountGetById, accountUpdate} from "../../../services/AccountService";
import {toast} from "react-toastify";
import DashboardAccountsEditEventDetails from "./DashboardAccountsEditEventDetails/DashboardAccountsEditEventDetails";
import ToggleField from "../../../components/Dashboard/DashbardForm/ToggleField/ToggleField";
import {useDispatch, useSelector} from "react-redux";
import {ConditionalWrapper} from "../../../components/ConditionalWrapper/ConditionalWrapper";
import DashboardAccountsEditEvents from "./DashboardAccountsEditEvents/DashboardAccountsEditEvents";
import {setAccountDetailsEditable} from "../../../store/actions/DashboardActions";
import TextField from "../../../components/Dashboard/DashbardForm/TextField/TextField";
import DashboardFormatTextField
    from "../../../components/Dashboard/DashbardForm/DashboardFormatTextField/DashboardFormatTextField";
import DashboardSelect from "../../../components/Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import {states} from "../../../utills/constants/general";

const DashboardAccountsEdit = (props) => {
    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(true);

    const {isAdmin} = useSelector(state => state.dashboardState.user)
    const {accountDetailsEditable} = useSelector(state => state.dashboardState)
    const {id} = useParams();
    const dispatch = useDispatch();

    const search = useLocation().search;
    const tab = new URLSearchParams(search).get('tab');
    const navigate = useNavigate();

    const AccountEditForm = useFormik({
        enableReinitialize: true,
        validationSchema: AccountFormSchema,
        initialValues: {
            name: data.name,
            phoneNumber: data.phoneNumber,
            city: data.city,
            state: data.state?.trim(),
            zip: data.zip,
            address: data.address,
            address2: data.address2,
            webSite: data.webSite,
            isActive: data.isActive || false
        },
        onSubmit: values => {
            const toastId = toast.loading("Please wait...");
            setDataLoading(true)

            accountUpdate({
                id: id,
                name: values.name,
                phoneNumber: values.phoneNumber,
                address: values.address,
                city: values.city,
                state: values.state,
                zip: values.zip,
                webSite: values.webSite,
                address2: values.address2,
                isActive: values.isActive
            }).then(response => {
                if (response.data.success === true) {
                    toast.update(toastId, {
                        render: "Account was updated successfully",
                        type: "success",
                        isLoading: false,
                        autoClose  : 5000
                    })
                    setData(response.data.data)
                } else {
                    toast.update(toastId, {
                        render: "Account updating was failed",
                        type: "error",
                        isLoading: false,
                        autoClose: 5000
                    })
                    navigate('/accounts')
                }
            }).catch(err => {
                toast.update(toastId, {
                    render: "Account updating was failed",
                    type: "error",
                    isLoading: false,
                    autoClose: 5000
                })
                navigate('/accounts')
            }).finally(() => setDataLoading(false))
        },
    });

    useEffect(() => {
        accountGetById(id).then((response) => {
            if (response.data.success === true) {
                dispatch(setAccountDetailsEditable(false))
                setData(response.data.data)
                console.log(response.data.data)
            }
        }).finally(() => {
            setDataLoading(false)
        })
    }, [id])

    if (dataLoading) {
        return (
            <div className="dashboard-card">
                <div className="dashboard-form">
                    <div className="scheduler-empty mt-5 pt-5 d-flex">
                        <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="dashboard-card">
            <div className="dashboard-form">
                <DashboardFormHeader title={`${data.name} / Account Info`} backTo={"/accounts"} action={
                    isAdmin ? (
                        <Button onClick={() => navigate('/accounts/create')}>
                            Add new Account
                        </Button>
                    ) : null
                }/>

                <Tabs defaultIndex={tab} selectedTabClassName={"active"} className={"dashboard-tabs"}>
                    <TabList className={"dashboard-tabs-list"}>
                        <Tab className={"dashboard-tabs-list-item"}>Account Details</Tab>
                        <Tab className={"dashboard-tabs-list-item"}>Event Details</Tab>
                        {isAdmin ? (
                            <Tab className={"dashboard-tabs-list-item"}>Events</Tab>
                        ) : null}
                    </TabList>

                    <TabPanel selectedClassName={"active"} className={"dashboard-tabs-content"}>
                        <div className="dashboard-form-card-info">
                            Internal ID: {data.id}
                        </div>

                        <ConditionalWrapper condition={!accountDetailsEditable} wrapper={children => <div className={"dashboard-disabled"}>{children}</div>}>
                            <form onSubmit={AccountEditForm.submitForm} className={"form-container"}>
                                <div className="row">
                                    <div className="dashboard-form-card-title mb-0">
                                        Account Details
                                    </div>
                                    <div className="dashboard-form-info">
                                        These are the your business details, we won’t share this data in the app.
                                    </div>
                                </div>
                                <div className="row">
                                    <TextField
                                        onChange={(e) => AccountEditForm.setFieldValue('name', e.target.value)}
                                        value={AccountEditForm.initialValues.name}
                                        errors={AccountEditForm.errors.name}
                                        touched={AccountEditForm.touched.name}
                                        label="*Account Name"
                                        placeholder="Account Name"
                                        className="col-6"/>
                                    <DashboardFormatTextField
                                        mask={"_"}
                                        format={"(###)-###-####"}
                                        onChange={(e) => AccountEditForm.setFieldValue('phoneNumber', e.target.value)}
                                        value={AccountEditForm.initialValues.phoneNumber}
                                        errors={AccountEditForm.errors.phoneNumber}
                                        touched={AccountEditForm.touched.phoneNumber}
                                        label="*Phone Number"
                                        placeholder="Phone Number"
                                        className="col-6"/>
                                </div>
                                <div className="row">
                                    <TextField
                                        onChange={(e) => AccountEditForm.setFieldValue('address', e.target.value)}
                                        value={AccountEditForm.initialValues.address}
                                        errors={AccountEditForm.errors.address}
                                        touched={AccountEditForm.touched.address}
                                        label="*Address 1"
                                        placeholder="Address 1"
                                        className="col-6"/>
                                    <TextField
                                        onChange={(e) => AccountEditForm.setFieldValue('address2', e.target.value)}
                                        value={AccountEditForm.initialValues.address2}
                                        errors={AccountEditForm.errors.address2}
                                        touched={AccountEditForm.touched.address2}
                                        label="Address 2"
                                        placeholder="Address 2"
                                        className="col-6"/>
                                </div>
                                <div className="row">
                                    <TextField
                                        onChange={(e) => AccountEditForm.setFieldValue('city', e.target.value)}
                                        value={AccountEditForm.initialValues.city}
                                        errors={AccountEditForm.errors.city}
                                        touched={AccountEditForm.touched.city}
                                        label="*City"
                                        placeholder="City"
                                        className="col-2"/>
                                    <DashboardSelect
                                        onChange={(e) => AccountEditForm.setFieldValue('state', e.target.value)}
                                        value={AccountEditForm.initialValues.state}
                                        errors={AccountEditForm.errors.state}
                                        touched={AccountEditForm.touched.state}
                                        label="*State"
                                        options={states}
                                        placeholder="State"
                                        className="col-2"/>
                                    <TextField
                                        onChange={(e) => AccountEditForm.setFieldValue('zip', e.target.value)}
                                        value={AccountEditForm.initialValues.zip}
                                        errors={AccountEditForm.errors.zip}
                                        touched={AccountEditForm.touched.zip}
                                        label="*Zip"
                                        placeholder="Zip"
                                        className="col-2"/>
                                </div>
                                <div className="row">
                                    <TextField
                                        onChange={(e) => AccountEditForm.setFieldValue('webSite', e.target.value)}
                                        value={AccountEditForm.initialValues.webSite}
                                        errors={AccountEditForm.errors.webSite}
                                        touched={AccountEditForm.touched.webSite}
                                        label="*Website"
                                        placeholder="Website"
                                        className="col-6"/>
                                </div>
                            </form>
                            {isAdmin ? (
                                <>
                                    <div className="row mt-3">
                                        <div className="dashboard-form-card-title mb-0">
                                            Account Status
                                        </div>
                                        <div className="dashboard-form-info mb-3">
                                            This is an admin only function to make this account live in Gowzon
                                        </div>
                                    </div>
                                    <div className="row">
                                        <ToggleField
                                            name={'active'}
                                            data={[{name: 'OFF', value: false}, {name: 'ON', value: true}]}
                                            onChange={(e) => AccountEditForm.setFieldValue('isActive', e === 'true')}
                                            value={AccountEditForm.initialValues.isActive}/>
                                    </div>
                                </>
                            ) : null}
                        </ConditionalWrapper>

                        <div className="d-flex gap-3">
                            <Button className={'mt-3'} onClick={() => dispatch(setAccountDetailsEditable(true))}>Edit Information</Button>
                            <Button className={'mt-3'} variant={!accountDetailsEditable ? 'primary disabled' : 'primary'} onClick={() => {
                                if(Object.values(AccountEditForm.errors).length === 0) {
                                    AccountEditForm.handleSubmit()
                                    dispatch(setAccountDetailsEditable(false))
                                } else {
                                    AccountEditForm.handleSubmit()
                                }
                            }}>Save</Button>
                        </div>
                    </TabPanel>
                    <TabPanel selectedClassName={"active"} className={"dashboard-tabs-content"}>
                        <DashboardAccountsEditEventDetails />
                    </TabPanel>
                    { isAdmin ? (
                    <TabPanel selectedClassName={"active"} className={"dashboard-tabs-content px-4"}>
                        <DashboardAccountsEditEvents />
                    </TabPanel>
                    ) : null }
                    {/*<TabPanel selectedClassName={"active"} className={"dashboard-tabs-content"}>*/}
                    {/*    <DashboardAccountsEditBilling/>*/}
                    {/*</TabPanel>*/}
                </Tabs>

            </div>
        </div>
    );
};

export default DashboardAccountsEdit;