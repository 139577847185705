import React from 'react';
import HeaderLogo from '../../assets/images/logo.svg'
import "./Header.scss";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <header className={"main-header"}>
            <div className="main-header-logo">
                <Link to={"/"}>
                    <img src={HeaderLogo} alt="growzon-header-logo" width={200} />
                </Link>
            </div>
        </header>
    );
};

export default Header;