import {
    Navigate, Outlet
} from 'react-router-dom';
import {ACCESS_TOKEN} from "../../../utills/constants/api";

function PublicRoute() {
    const isAuthenticated = localStorage.getItem(ACCESS_TOKEN);

    return isAuthenticated ? <Navigate to="/home" /> : <Outlet /> ;
}

export default PublicRoute;