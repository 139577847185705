import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/Login/Login/Login";
import Header from "./components/Header/Header";
import DashboardLayout from "./components/Dashboard/DashboardLayout/DashboardLayout";
import DashboardAccounts from "./pages/DashboardAccounts/DashboardAccounts/DashboardAccounts";
import DashboardAccountsEdit from "./pages/DashboardAccounts/DashboardAccountsEdit/DashboardAccountsEdit";
import DashboardUsers from "./pages/DashboardUsers/DashboardUsers/DashboardUsers";
import DashboardUsersEdit from "./pages/DashboardUsers/DashboardUsersEdit/DashboardUsersEdit";
import DashboardAnalytics from "./pages/DashboardAnalytics/DashboardAnalytics/DashboardAnalytics";
import DashboardMedia from "./pages/DashboardMedia/DashboardMedia/DashboardMedia";
import Signup from "./pages/Signup/Signup/Signup";
import SignupSuccess from "./pages/Signup/SignupSuccess/SignupSuccess";
import PrivateRoute from "./components/Routes/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute/PublicRoute";
import DashboardHome from "./pages/DashboardHome/DashboardHome/DashboardHome";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DashboardEvents from "./pages/DashboardEvents/DashboardEvents/DashboardEvents";
import DashboardEventsCreate from "./pages/DashboardEvents/DashboardEventsCreate/DashboardEventsCreate";
import DashboardEventsEdit from "./pages/DashboardEvents/DashboardEventsEdit/DashboardEventsEdit";
import { Provider } from 'react-redux'
import store from "./store/store";
import DashboardUsersCreate from "./pages/DashboardUsers/DashboardUsersCreate/DashboardUsersCreate";
import DashboardAccountsCreate from "./pages/DashboardAccounts/DashboardAccountsCreate/DashboardAccountsCreate";
import DashboardCampaigns from "./pages/DashboardCampaigns/DashboardCampaigns";
import './assets/images/login_cta.png';
import './assets/images/gowzon.png';
import DashboardApprovals from "./pages/DashboardApprovals/DashboardApprovals";
import PasswordReset from "./pages/PasswordReset/PasswordReset/PasswordReset";
import PasswordResetInfo from "./pages/PasswordReset/PasswordResetInfo/PasswordResetInfo";
import PasswordChangeForm from "./pages/PasswordChange/PasswordChange/PasswordChangeForm/PasswordChangeForm";
import PasswordChange from "./pages/PasswordChange/PasswordChange/PasswordChange";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route path={"/"} element={<Navigate to={"/sign-in"}/>}/>

                    <Route path="/" element={<PublicRoute/>}>
                        <Route exact path={"sign-in"} element={<Login/>}/>
                        <Route exact path={"reset-password"} element={<PasswordReset/>}/>
                        <Route exact path={"reset-password/success"} element={<PasswordResetInfo/>}/>
                        <Route exact path={"change-password"} element={<PasswordChange/>}/>
                        <Route exact path={"change-password/success"} element={<PasswordResetInfo/>}/>
                        <Route exact path={"sign-up/success"} element={<SignupSuccess/>}/>

                        <Route path={"*"} element={<Navigate to={"/sign-in"}/>}/>
                    </Route>

                    <Route path="/" element={<PrivateRoute/>}>
                        <Route element={<DashboardLayout/>}>
                            <Route exact path={"accounts/"} element={<DashboardAccounts/>}>
                                <Route exact path={":page"} element={<DashboardAccounts/>}/>
                            </Route>
                            <Route exact path={"accounts/edit/:id"} element={<DashboardAccountsEdit/>}/>
                            <Route exact path={"accounts/create"} element={<DashboardAccountsCreate/>}/>

                            <Route exact path={"campaigns/"} element={<DashboardCampaigns/>}>
                                <Route exact path={":page"} element={<DashboardCampaigns/>}/>
                            </Route>

                            <Route exact path={"events/"} element={<DashboardEvents/>}>
                                <Route exact path={":page"} element={<DashboardEvents/>}/>
                            </Route>
                            <Route exact path={"events/create"} element={<DashboardEventsCreate/>}/>
                            <Route exact path={"events/edit/:id"} element={<DashboardEventsEdit/>}/>

                            <Route exact path={"approvals/"} element={<DashboardApprovals/>}>
                                <Route exact path={":page"} element={<DashboardApprovals/>}/>
                            </Route>

                            <Route exact path={"media"} element={<DashboardMedia/>}/>

                            <Route exact path={"analytics"} element={<DashboardAnalytics/>}>
                                <Route exact path={":page"} element={<DashboardAnalytics/>}/>
                            </Route>

                            <Route exact path={"users/"} element={<DashboardUsers/>}>
                                <Route exact path={":page"} element={<DashboardUsers/>}/>
                            </Route>
                            <Route exact path={"users/edit/:id"} element={<DashboardUsersEdit/>}/>
                            <Route exact path={"users/create"} element={<DashboardUsersCreate/>}/>

                            <Route path={"home"} element={<DashboardHome/>}/>
                            <Route path={"*"} element={<Navigate to={"/home"}/>}/>
                        </Route>

                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer
                toastClassName={"dashboard-toast"}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Provider>
    );
}

export default App;
