import React, {useEffect, useState, Fragment} from 'react';
import SortIcon from "../../../../assets/images/sort-icon.svg";
import {useSortBy, useTable} from "react-table";
import {useExpanded} from "react-table";

const DashboardAnalyticsTable = ({analytics}) => {
    const [data, setData] = useState(analytics);

    const columns = React.useMemo(
        () => [
            {
                id: 'label',
                Cell: ({cell: {value}}) => <strong>All Time</strong>
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Impressions',
                accessor: 'show',
                Cell: ({cell: {value}}) => <span className={"opacity-50"}>{value}</span>
            },
            {
                Header: 'Clicks',
                accessor: 'detail',
                Cell: ({cell: {value}}) => <b className={"text-primary"}>{value}</b>
            },
            {
                Header: 'Shares',
                accessor: 'share',
                Cell: ({cell: {value}}) => <b className={"text-secondary"}>{value}</b>
            },
            {
                Header: 'Calls',
                accessor: 'phoneCall',
                Cell: ({cell: {value}}) => <b className={"text-secondary"}>{value}</b>
            },{
                Header: 'Website',
                accessor: 'visitWebsite',
                Cell: ({cell: {value}}) => <b className={"text-secondary"}>{value}</b>
            }
        ],
        [])

    useEffect(() => {
        setData(analytics)
    }, [analytics])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        useExpanded,
    )

    return (
        <table {...getTableProps()} className={"dashboard-analytics-table"}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr className={"dashboard-card"} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                                        : <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                                    : null}
                              </span>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(
                (row, i) => {
                    prepareRow(row);
                    return (
                        <Fragment {...row.getRowProps()}>
                            <tr className={"dashboard-card"}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    )
                                })}
                            </tr>
                                {/*<tr className={"dashboard-analytics-table-expand"}>*/}
                                {/*    <td colSpan="6">*/}
                                {/*        <DashboardAnalyticsTableInfo data={row.original.info}/>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                        </Fragment>);
                }
            )}
            </tbody>
        </table>
    );
};

export default DashboardAnalyticsTable;