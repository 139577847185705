import React from 'react';
import TextField from "../../Dashboard/DashbardForm/TextField/TextField";
import DashboardFormatTextField from "../../Dashboard/DashbardForm/DashboardFormatTextField/DashboardFormatTextField";
import DashboardSelect from "../../Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import {states} from "../../../utills/constants/general";

const AccountForm = ({AccountForm}) => {
    return (
        <form onSubmit={AccountForm.submitForm} className={"form-container"}>
            <div className="row">
                <div className="dashboard-form-card-title mb-0">
                    Account Details
                </div>
                <div className="dashboard-form-info">
                    These are the your business details, we won’t share this data in the app.
                </div>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountForm.setFieldValue('name', e.target.value)}
                    value={AccountForm.initialValues.name}
                    errors={AccountForm.errors.name}
                    touched={AccountForm.touched.name}
                    label="*Account Name"
                    placeholder="Account Name"
                    className="col-6"/>
                <DashboardFormatTextField
                    mask={"_"}
                    format={"(###)-###-####"}
                    onChange={(e) => AccountForm.setFieldValue('phoneNumber', e.target.value)}
                    value={AccountForm.initialValues.phoneNumber}
                    errors={AccountForm.errors.phoneNumber}
                    touched={AccountForm.touched.phoneNumber}
                    label="*Phone Number"
                    placeholder="Phone Number"
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountForm.setFieldValue('address', e.target.value)}
                    value={AccountForm.initialValues.address}
                    errors={AccountForm.errors.address}
                    touched={AccountForm.touched.address}
                    label="*Address 1"
                    placeholder="Address 1"
                    className="col-6"/>
                <TextField
                    onChange={(e) => AccountForm.setFieldValue('address2', e.target.value)}
                    value={AccountForm.initialValues.address2}
                    errors={AccountForm.errors.address2}
                    touched={AccountForm.touched.address2}
                    label="Address 2"
                    placeholder="Address 2"
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountForm.setFieldValue('city', e.target.value)}
                    value={AccountForm.initialValues.city}
                    errors={AccountForm.errors.city}
                    touched={AccountForm.touched.city}
                    label="*City"
                    placeholder="City"
                    className="col-2"/>
                <DashboardSelect
                    onChange={(e) => AccountForm.setFieldValue('state', e.target.value)}
                    value={AccountForm.initialValues.state}
                    errors={AccountForm.errors.state}
                    touched={AccountForm.touched.state}
                    label="*State"
                    options={states}
                    placeholder="State"
                    className="col-2"/>
                <TextField
                    onChange={(e) => AccountForm.setFieldValue('zip', e.target.value)}
                    value={AccountForm.initialValues.zip}
                    errors={AccountForm.errors.zip}
                    touched={AccountForm.touched.zip}
                    label="*Zip"
                    placeholder="Zip"
                    className="col-2"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => AccountForm.setFieldValue('webSite', e.target.value)}
                    value={AccountForm.initialValues.webSite}
                    errors={AccountForm.errors.webSite}
                    touched={AccountForm.touched.webSite}
                    label="*Website"
                    placeholder="Website"
                    className="col-6"/>
            </div>
        </form>
    );
};

export default AccountForm;