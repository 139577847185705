import React from 'react';
import {Link} from "react-router-dom";
import catImage from '../../../assets/images/cat.png';

const PasswordResetInfo = () => {
    return (
        <div className={"container d-flex justify-content-center"}>
            <div className={"homepage-form mb-5"}>
                <h2 className={"homepage-form-title mb-2"}>
                    <Link className={"homepage-form-title-backlink"} to={"/sign-in"}>
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.49887 1.61726C6.85935 1.24729 6.85935 0.647448 6.49887 0.277478C6.13838 -0.0924926 5.55392 -0.0924926 5.19344 0.277478L0.270363 5.33011C0.090121 5.51509 0 5.75755 0 6C0 6.12845 0.0249097 6.25094 0.0700419 6.36264C0.115088 6.47437 0.181862 6.57906 0.270363 6.66989L5.19344 11.7225C5.55392 12.0925 6.13838 12.0925 6.49887 11.7225C6.85935 11.3526 6.85935 10.7527 6.49887 10.3827L3.15158 6.94737H15.0769C15.5867 6.94737 16 6.52322 16 6C16 5.47678 15.5867 5.05263 15.0769 5.05263H3.15158L6.49887 1.61726Z" fill="#0F0F1A"/>
                        </svg>
                    </Link>
                    Success
                </h2>
                <p className="homepage-form-info">
                    A notification was sent to the e-mail address you provided during registration to generate a new password. <br/>
                </p>
                <div className="text-center">
                    <img style={{width: '75%'}} src={catImage} alt=""/>
                </div>

            </div>
        </div>
    );
};

export default PasswordResetInfo;