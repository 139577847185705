import React, {useState} from 'react';
import InfoIcon from "../../../../assets/images/pink-info-icon.svg";
import Button from "../../../../components/Button/Button";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {userPasswordReset} from "../../../../services/UserService";
import {toast} from "react-toastify";

const PasswordResetForm = () => {
    const navigate = useNavigate();
    const [passwordInputInitType, setPasswordInputInitType] = useState('password');

    const PasswordResetForm = useFormik({
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Email is required')
        }),
        initialValues: {
            email: ''
        },
        onSubmit: values => {
            userPasswordReset(values.email).then(response => {
                console.log(response)
                if (response.data.success === true) {
                    navigate('/reset-password/success')
                } else {
                    toast.error(`${response.data.errors[0].message ? response.data.errors[0].message : 'Something goes wrong'}`, {
                        autoClose: 5000
                    })
                }
            })
        },
    });

    return (
        <div className={"homepage-form"}>
            <h2 className={"homepage-form-title"}>Password Reset</h2>
            <form onSubmit={PasswordResetForm.handleSubmit}>
                <div className="homepage-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        onChange={(e) => PasswordResetForm.handleChange(e)}
                        value={PasswordResetForm.values.email}
                    />
                    {PasswordResetForm.errors.email && PasswordResetForm.touched.email ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{PasswordResetForm.errors.email}
                        </div>
                    ) : null}
                </div>

                <div className="button-container">
                    <input type={"submit"} className={"button button-primary"} onSubmit={PasswordResetForm.handleSubmit} value={"Submit"} />
                </div>
            </form>

            <Link className={"homepage-form-link"} to={"/sign-in"}>Log in</Link>
            <div className={"homepage-form-link"} onClick={() =>
                window.location.href = 'https://gowzon.com/advertise-with-us'}>Don’t have an account? Register Now</div>
        </div>
    );
};

export default PasswordResetForm;