import React from 'react';
import DashboardSidebar from "../../../components/Dashboard/DashboardSidebar/DashboardSidebar";
import "./DashboardHome.scss";
import Button from "../../../components/Button/Button";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";

const DashboardHome = () => {

    const navigate = useNavigate();

    const {isAdmin} = useSelector(state => state.dashboardState.user)
    const {isAdvertiser} = useSelector(state => state.dashboardState.user)

    return (
        <>
            <div className="dashboard-title">
                Gowzon Advertising Portal
            </div>
            <div className="dashboard-card dashboard-card-secondary d-flex align-items-center">

                <div className="featured-list">
                    <div className="featured-list-item">
                        <div className="featured-list-item-image">
                            <svg width="77" height="79" viewBox="0 0 77 79" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M75.3813 66.1952C77.3837 46.9501 77.3837 27.5491 75.3813 8.30393C74.6673 1.44121 66.7546 -2.03511 61.2172 2.08113L42.7508 15.808C37.1238 19.9908 30.2989 22.2495 23.2875 22.2495H6.05615C2.94955 22.2495 0.431152 24.7679 0.431152 27.8745V46.6245C0.431152 49.7311 2.94954 52.2495 6.05615 52.2495H8.8654L5.0613 66.4467C4.63739 68.0287 5.41054 69.6867 6.89493 70.3789L23.4601 78.1036C24.3642 78.5252 25.4086 78.5252 26.3128 78.1036C27.2169 77.682 27.8882 76.882 28.1465 75.9184L33.8286 54.7124C33.8889 54.4872 33.925 54.2611 33.9384 54.0371C37.0771 55.1211 40.0517 56.6848 42.7508 58.6911L61.2172 72.418C66.7546 76.5342 74.6673 73.0579 75.3813 66.1952ZM68.6676 9.00247C70.6216 27.7832 70.6216 46.716 68.6676 65.4966C68.495 67.1554 66.5825 67.9956 65.244 67.0007L46.7777 53.2739C39.9864 48.2256 31.7495 45.4995 23.2875 45.4995L7.18115 45.4995L7.18115 28.9995H23.2875C31.7495 28.9995 39.9864 26.2735 46.7777 21.2252L65.2441 7.49839C66.5825 6.50349 68.495 7.34371 68.6676 9.00247ZM27.4624 52.5177C26.0836 52.3398 24.6893 52.2495 23.2875 52.2495H15.8535L12.3143 65.4582L22.6675 70.2862L27.3086 52.9654C27.35 52.8106 27.4016 52.6612 27.4624 52.5177Z"
                                      fill="#6B6B89"/>
                            </svg>
                        </div>
                        <div className="featured-list-item-description">
                            <div className="featured-list-item-title">Scheduler</div>
                            Setup or edit your events
                        </div>
                        <Button onClick={() => navigate(`${!isAdmin && isAdvertiser ? `/campaigns` : '/events'}`)}>
                            Schedule Events
                        </Button>
                    </div>
                    <div className="featured-list-item">
                        <div className="featured-list-item-image">
                            <svg width="75" height="75" viewBox="0 0 75 75" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M37.5 32.1429C39.2752 32.1429 40.7143 33.5819 40.7143 35.3571V56.7857C40.7143 58.5609 39.2752 60 37.5 60C35.7248 60 34.2857 58.5609 34.2857 56.7857V35.3571C34.2857 33.5819 35.7248 32.1429 37.5 32.1429Z"
                                    fill="#6B6B89"/>
                                <path
                                    d="M37.5 24.6429C39.8669 24.6429 41.7857 22.7241 41.7857 20.3571C41.7857 17.9902 39.8669 16.0714 37.5 16.0714C35.1331 16.0714 33.2143 17.9902 33.2143 20.3571C33.2143 22.7241 35.1331 24.6429 37.5 24.6429Z"
                                    fill="#6B6B89"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5ZM37.5 6.42857C20.3397 6.42857 6.42857 20.3397 6.42857 37.5C6.42857 54.6603 20.3397 68.5714 37.5 68.5714C54.6603 68.5714 68.5714 54.6603 68.5714 37.5C68.5714 20.3397 54.6603 6.42857 37.5 6.42857Z"
                                      fill="#6B6B89"/>
                            </svg>
                        </div>
                        <div className="featured-list-item-description">
                            <div className="featured-list-item-title">Help</div>
                            We all need help from time to time. Contact us now.
                        </div>
                        <a className={"button button-primary"} href={"mailto:support@gowzon.com"}>
                            Contact Support
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardHome;