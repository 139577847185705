import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import "./SignupForm.scss"
import Button from "../../../../components/Button/Button";
import {Link, useNavigate} from "react-router-dom";
import InfoIcon from '../../../../assets/images/pink-info-icon.svg'
import YupPassword from 'yup-password'
import {accountSignup} from "../../../../services/UserService";
import {ACCESS_TOKEN} from "../../../../utills/constants/api";
YupPassword(Yup)

const SignupFormSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'Password must by longer than 6 symbols')
        .minUppercase(1, 'Password must contains at least one uppercase letter')
        .minLowercase(1, 'Password must contains at least one lowercase letter')
        .minNumbers(1, 'Password must contains at least one number')
        .minSymbols(1, 'Password must contains at least one special symbols')
        .required('Password is required'),
    repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Repeat Password is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    contactPerson: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
    companyName: Yup.string().required('This field is required'),
    address: Yup.string().required('This field is required')
});

const SignupForm = () => {
    const navigate = useNavigate();
    const [passwordInputInitType, setPasswordInputInitType] = useState('password');
    const [signupErrors, setSignupErrors] = useState([]);

    const LoginForm = useFormik(
        {
        validationSchema: SignupFormSchema,
        initialValues: {
            email: '',
            password: '',
            repeatPassword: '',
            contactPerson: '',
            phoneNumber: '',
            companyName: '',
            address: ''
        },
        onSubmit: values => {
            accountSignup(values.email, values.password)
            .then(response => {
                if (response.data.success === true) {
                    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data.data))
                    navigate('/sign-up/success')
                } else {
                    setSignupErrors(response.data.errors)
                }
            })
        },
    });

    return (
        <div className={"homepage-form mb-5"}>
            <h2 className={"homepage-form-title mb-4"}>
                <Link className={"homepage-form-title-backlink"} to={"/sign-in"}>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.49887 1.61726C6.85935 1.24729 6.85935 0.647448 6.49887 0.277478C6.13838 -0.0924926 5.55392 -0.0924926 5.19344 0.277478L0.270363 5.33011C0.090121 5.51509 0 5.75755 0 6C0 6.12845 0.0249097 6.25094 0.0700419 6.36264C0.115088 6.47437 0.181862 6.57906 0.270363 6.66989L5.19344 11.7225C5.55392 12.0925 6.13838 12.0925 6.49887 11.7225C6.85935 11.3526 6.85935 10.7527 6.49887 10.3827L3.15158 6.94737H15.0769C15.5867 6.94737 16 6.52322 16 6C16 5.47678 15.5867 5.05263 15.0769 5.05263H3.15158L6.49887 1.61726Z" fill="#0F0F1A"/>
                    </svg>
                </Link>
                Advertiser Registration
            </h2>

            <div className={"homepage-form-info mb-4"}>Create your account to get started with Gowzon</div>

            { signupErrors.length !== 0 ? (
                <div className="homepage-form-errors">
                    { signupErrors.map((el ) => {
                        return <span key={el.code}>{el.message}</span>
                    })}
                </div>
            ) : null }

            <form onSubmit={LoginForm.handleSubmit}>

                <div className="homepage-form-group">
                    <label htmlFor="contactPerson">Contact Person</label>
                    <input
                        id="contactPerson"
                        name="contactPerson"
                        type="contactPerson"
                        placeholder="Enter your First Name"
                        onChange={(e) => LoginForm.handleChange(e)}
                        value={LoginForm.values.contactPerson}
                    />
                    { LoginForm.errors.contactPerson && LoginForm.touched.contactPerson ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.contactPerson}
                        </div>
                    ) : null }
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                        id="phoneNumber"
                        name="phoneNumber"
                        type="phoneNumber"
                        placeholder="Enter your phone number"
                        onChange={(e) => LoginForm.handleChange(e)}
                        value={LoginForm.values.phoneNumber}
                    />
                    { LoginForm.errors.phoneNumber && LoginForm.touched.phoneNumber ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.phoneNumber}
                        </div>
                    ) : null }
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="companyName">Company Name</label>
                    <input
                        id="companyName"
                        name="companyName"
                        type="companyName"
                        placeholder="Enter your Company Name"
                        onChange={(e) => LoginForm.handleChange(e)}
                        value={LoginForm.values.companyName}
                    />
                    { LoginForm.errors.companyName && LoginForm.touched.companyName ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.companyName}
                        </div>
                    ) : null }
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="address">City, State</label>
                    <input
                        id="address"
                        name="address"
                        type="address"
                        placeholder="Add your zip code"
                        onChange={(e) => LoginForm.handleChange(e)}
                        value={LoginForm.values.address}
                    />
                    { LoginForm.errors.address && LoginForm.touched.address ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.address}
                        </div>
                    ) : null }
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        onChange={(e) => LoginForm.handleChange(e)}
                        value={LoginForm.values.email}
                    />
                    { LoginForm.errors.email && LoginForm.touched.email ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.email}
                        </div>
                    ) : null }
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        name="password"
                        type={passwordInputInitType}
                        placeholder="Enter your password"
                        onChange={LoginForm.handleChange}
                        value={LoginForm.values.password}
                    />
                    <div onClick={() => setPasswordInputInitType(passwordInputInitType === "text" ? "password" : "text")}
                         className={`dashboard-form-group-input-icon ${passwordInputInitType}`}/>
                    { LoginForm.errors.password && LoginForm.touched.password ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.password}
                        </div>
                    ) : null }
                </div>

                <div className="homepage-form-group">
                    <label htmlFor="repeatPassword">Repeat Password</label>
                    <input
                        id="repeatPassword"
                        name="repeatPassword"
                        type={passwordInputInitType}
                        placeholder="Enter your repeat password"
                        onChange={LoginForm.handleChange}
                        value={LoginForm.values.repeatPassword}
                    />
                    <div onClick={() => setPasswordInputInitType(passwordInputInitType === "text" ? "password" : "text")}
                         className={`dashboard-form-group-input-icon ${passwordInputInitType}`}/>
                    { LoginForm.errors.repeatPassword && LoginForm.touched.repeatPassword ? (
                        <div className="homepage-form-group-error">
                            <img src={InfoIcon} alt=""/>{LoginForm.errors.repeatPassword}
                        </div>
                    ) : null }
                </div>

                <div className="button-container">
                    <input type={"submit"} className={"button button-primary"} onSubmit={LoginForm.handleSubmit} value={"Next"} />
                </div>
            </form>
        </div>
    );
};

export default SignupForm;