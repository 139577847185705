import axios from "axios";
import {ACCESS_TOKEN, API_BASE_URL} from "../utills/constants/api";
import {toast} from "react-toastify";
import {updateToken} from "./UserService";

axios.interceptors.request.use(config => {
    if (localStorage.getItem(ACCESS_TOKEN) !== null) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;

    if(error.response?.status === 401 || error.response?.status === 403) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));

        return updateToken().then(response => {
            if (response.data.success === true) {
                localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data.data));

                axios.defaults.headers.Authorization = 'Bearer ' + response.data.data.token;

                return axios(originalRequest);
            } else {
                localStorage.removeItem(ACCESS_TOKEN);
                toast.error('Your account token has been expired, please log in again.', {delay: 3000});
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            }
        })
    }
    return Promise.reject(error);
});

export const employersGet = async (page, pageCount, userSearch = '', sortDir = -1, sortType = -1) => {
    if (page === undefined || page === null || page === 0) {
        page = 1
    }

    const data = {
        'page': parseInt(page),
        'pageSize': parseInt(localStorage.getItem('userPerPage')) || 10,
        'search': userSearch,
    }

    if (sortDir !== -1) {
        data.sortDir = sortDir;
    }

    if (sortType !== -1) {
        data.sortField = sortType;
    }

    return await axios.post(API_BASE_URL + '/users/list', data,
        {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const employersGetById = async (id) => {
    return await axios.post(API_BASE_URL + `/account/user-info/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const employersUpdate = async (id, data) => {
    return await axios.post(API_BASE_URL + `/account/update`,{
        userId: id, ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'Access-Control-Allow-Origin': '*'
        }
    })
}