import React, {useEffect, useRef, useState} from 'react';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {DashboardTextareaClipboard} from "./DashboardTextareaClipboard";
import utf8 from "utf8";

Quill.register("modules/clipboard", DashboardTextareaClipboard, true);

const DashboardTextarea = ({
   label,
   placeholder,
   value,
   errors,
   help,
   inputContainerClassName,
   touched,
   onChange, max,
   ...props
}) => {
    const [filedValue, setFiledValue] = useState(value === null ? '' : value);
    const [fieldLength, setFieldLength] = useState(0)
    const [maxLength, setMaxLength] = useState(max+1)
    const reactQuillRef = useRef();

    useEffect(() => {
        setFiledValue(value)
        setFieldLength(reactQuillRef.current?.getEditor().getLength())
    }, [value, reactQuillRef])

    const modules = React.useMemo(
        () => ({
            toolbar: [
                ['empty', 'bold', 'italic', 'underline', 'strike'],
                [
                    {list: 'ordered'},
                    {list: 'bullet'},
                ],
                ['clean'],
            ]
        }),
        []
    )

    const checkCharacterCount = (event) => {
        const unprivilegedEditor = reactQuillRef.current.getEditor();
        if (unprivilegedEditor.getLength() > max && event.key !== 'Backspace'){
            event.preventDefault();
        }
    };

    return (
        <div className={`dashboard-form-group ${props.className}`}>
            <label className={"align-content-between"}>
                {label} | Characters left: {
                    reactQuillRef?.current?.getEditor().getLength() ?
                        maxLength - reactQuillRef?.current?.getEditor().getLength() > 0 ? maxLength - reactQuillRef?.current?.getEditor().getLength() : 0
                    : maxLength }
                <div className={`dashboard-form-group-input flex-column ${inputContainerClassName}`}>
                    <ReactQuill theme="snow"
                        onKeyDown={checkCharacterCount}
                        ref={reactQuillRef}
                        modules={modules}
                        placeholder={placeholder}
                        value={filedValue}
                        onChange={(value) => {
                            setFiledValue(utf8.decode(utf8.encode(value)))
                            onChange(utf8.decode(utf8.encode(value)))
                        }}/>

                    {/*<TextareaAutosize minRows={5} maxRows={15} placeholder={placeholder} value={filedValue} onChange={(e) => {*/}
                    {/*    setFiledValue(e.target.value.slice(0, 750));*/}
                    {/*    onChange(e)*/}
                    {/*}} />*/}
                </div>
            </label>
            {help ? (
                <div className='dashboard-form-group-help'>
                    {help}
                </div>
            ) : null}

            {errors && touched ? (
                <div className="dashboard-form-group-errors">
                    {errors}
                </div>
            ) : null}
        </div>
    );
};

export default DashboardTextarea;