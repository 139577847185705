import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useFormik} from "formik";
import {UserFormSchema} from "../../../utills/constants/schemas/user";
import {toast} from "react-toastify";
import {employersGetById, employersUpdate} from "../../../services/EmployesService";
import DashboardFormHeader from "../../../components/Dashboard/DashbardForm/DashboardFormHeader/DashboardFormHeader";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/Dashboard/DashbardForm/TextField/TextField";
import DashboardFormatTextField
    from "../../../components/Dashboard/DashbardForm/DashboardFormatTextField/DashboardFormatTextField";
import ToggleField from "../../../components/Dashboard/DashbardForm/ToggleField/ToggleField";
import DashboardSelect from "../../../components/Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import {states} from "../../../utills/constants/general";
import {changePassword, resetPassword} from "../../../services/UserService";
import {useSelector} from "react-redux";

const DashboardUsersCreate = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const { isAdmin } = useSelector(state => state.dashboardState.user)

    useEffect(() => {
        if (!isAdmin){
            toast.error('You have no access to this page')
            navigate('/home')
        }
    })

    const AccountEditForm = useFormik({
        validationSchema: UserFormSchema,
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            city: '',
            state: states[0],
            zip: '',
            address: '',
            email: '',
            userName: '',
            password: '',
            confirmPassword: '',
            isActive: false
        },
        onSubmit: values => {
            const toastId = toast.loading("Please wait...")

            employersUpdate(id, {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                companyId: 1,
                phoneNumber: values.phoneNumber,
                city: values.city,
                state: values.state,
                zip: values.zip,
                userName: values.userName,
                password: values.password,
                confirmPassword: values.confirmPassword,
                roles: ['Advertiser'],
                isActive: (values.isActive === 'true')
            }).then(response => {
                if (response.data.success === true) {
                    toast.update(toastId, {
                        render: "User was created successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    })
                    navigate(`/users/edit/${response.data.data.userId}`)
                } else {
                    toast.update(toastId, {
                        render: "User creating failed",
                        type: "error",
                        isLoading: false,
                        autoClose: 5000
                    })
                }
            }).catch((err) => {
                toast.update(toastId, {
                    render: "User creating failed",
                    type: "error",
                    isLoading: false,
                    autoClose: 5000
                })
            } )
        },
    });

    return (
        <div className="dashboard-card">
            <div className="dashboard-form">
                <DashboardFormHeader title={'Create User'} backTo={"/users"}/>
                <div className="dashboard-form-card">
                    <div className="dashboard-form-card-title">
                        Details
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('firstName', e.target.value)}
                            value={AccountEditForm.initialValues.firstName}
                            errors={AccountEditForm.errors.firstName}
                            touched={AccountEditForm.touched.firstName}
                            label="First Name"
                            placeholder="First Name"
                            className="col-6"/>
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('lastName', e.target.value)}
                            value={AccountEditForm.initialValues.lastName}
                            errors={AccountEditForm.errors.lastName}
                            touched={AccountEditForm.touched.lastName}
                            label="Last Name"
                            placeholder="Last Name"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <DashboardFormatTextField
                            onChange={(e) => AccountEditForm.setFieldValue('phoneNumber', e.target.value)}
                            value={AccountEditForm.initialValues.phoneNumber}
                            errors={AccountEditForm.errors.phoneNumber}
                            touched={AccountEditForm.touched.phoneNumber}
                            label="Phone Number"
                            placeholder="Phone Number"
                            mask={"_"}
                            format={"(###)-###-####"}
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('city', e.target.value)}
                            value={AccountEditForm.initialValues.city}
                            errors={AccountEditForm.errors.city}
                            touched={AccountEditForm.touched.city}
                            label="City"
                            placeholder="City"
                            className="col-6"/>
                        <DashboardSelect
                            options={states}
                            onChange={(e) => AccountEditForm.setFieldValue('state', e.target.value)}
                            value={AccountEditForm.initialValues.state}
                            errors={AccountEditForm.errors.state}
                            touched={AccountEditForm.touched.state}
                            label="State"
                            placeholder="State"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('zip', e.target.value)}
                            value={AccountEditForm.initialValues.zip}
                            errors={AccountEditForm.errors.zip}
                            touched={AccountEditForm.touched.zip}
                            label="Zip"
                            placeholder="Zip"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('email', e.target.value)}
                            value={AccountEditForm.initialValues.email}
                            errors={AccountEditForm.errors.email}
                            touched={AccountEditForm.touched.email}
                            label="Email"
                            placeholder="aldente@acme.com"
                            className="col-6"/>
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('userName', e.target.value)}
                            value={AccountEditForm.initialValues.userName}
                            errors={AccountEditForm.errors.userName}
                            touched={AccountEditForm.touched.userName}
                            label="Username"
                            placeholder="aldente@acme.com"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('password', e.target.value)}
                            value={AccountEditForm.initialValues.password}
                            errors={AccountEditForm.errors.password}
                            touched={AccountEditForm.touched.password}
                            type="password"
                            label="Password"
                            className="col-6"/>
                        <TextField
                            onChange={(e) => AccountEditForm.setFieldValue('confirmPassword', e.target.value)}
                            value={AccountEditForm.initialValues.confirmPassword}
                            errors={AccountEditForm.errors.confirmPassword}
                            touched={AccountEditForm.touched.confirmPassword}
                            type="password"
                            label="Repeat Password"
                            className="col-6"/>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <ToggleField
                                name={'active'}
                                data={[{name: 'OFF', value: false}, {name: 'ON', value: true}]}
                                onChange={(e) => AccountEditForm.setFieldValue('isActive', e)}
                                value={AccountEditForm.initialValues.isActive}
                                label={'Active'}/>
                        </div>
                    </div>
                    <Button onClick={AccountEditForm.handleSubmit}>Submit</Button>
                </div>
            </div>
        </div>
    );
};

export default DashboardUsersCreate;