import React, {useEffect, useState} from 'react';
import Table from "../../../../components/Table/Table";
import Button from "../../../../components/Button/Button";
import {Link} from "react-router-dom";
import ModalImage from "react-modal-image";
import DashboardEventsFilter from "../DashboardEventsFilter/DashboardEventsFilter";
import {eventDelete, eventGetAll, eventGetAllAccount} from "../../../../services/EventService";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import {
    setAccountsSortDir,
    setAccountsSortType,
    setEventPageCount, setEventsSortDir, setEventsSortType,
    setSelectedEvents
} from "../../../../store/actions/DashboardActions";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import {accountsGet} from "../../../../services/UserService";
import DashboardSelect from "../../../../components/Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import SortIcon from "../../../../assets/images/sort-icon.svg";

const DashboardEventsTable = ({page, eventPerPage, myCampaing}) => {
    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [deleteId, setDeleteId] = useState(0);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [accountId, setAccountId] = useState(null);
    const [accounts, setAccounts] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {selectedEvents} = useSelector(state => state.dashboardState);
    const {userId} = useSelector(state => state.dashboardState.user);
    const {isAdmin} = useSelector(state => state.dashboardState.user)
    const {isAdvertiser} = useSelector(state => state.dashboardState.user)
    const {eventsSortDir} = useSelector(state => state.dashboardState)
    const {eventsSortType} = useSelector(state => state.dashboardState)

    const columnsIds = ['events-name-sort', 'events-company-sort'];

    const columns = React.useMemo(() => [
        {
            Header: 'Creative',
            accessor: 'thubnailUrl',
            disableSortBy: true,
            Cell: ({cell: {value}}) => (
                <ModalImage style={{overlay: {zIndex: 10000}}} showRotate={true} small={value} large={value}
                            className={"dashboard-analytics-table-content-creative"}/>
            )
        }, {
            Header: () => <div className={"cursor-pointer"} id={'events-company-sort'} data-sort={eventsSortDir === 0} data-sort-desc={eventsSortDir === 1} onClick={() => {
                columnsIds.filter(el => el !== 'events-company-sort').forEach(el => {
                    document.getElementById(el).dataset.sort = 'false';
                    document.getElementById(el).dataset.sortDesc = 'false';
                })
                const sort = document.getElementById('events-company-sort')
                let isDataSort = sort.dataset.sort === 'true';
                let isDataSortDesc = sort.dataset.sortDesc === 'true';

                if (isDataSort) {
                    sort.dataset.sort = 'false'
                    sort.dataset.sortDesc = 'true'
                    dispatch(setEventsSortDir(1))
                } else if (isDataSortDesc) {
                    sort.dataset.sortDesc = 'false'
                    dispatch(setEventsSortDir(-1))
                    dispatch(setEventsSortType(-1))
                } else {
                    sort.dataset.sort = 'true'
                    dispatch(setEventsSortDir(0))
                    dispatch(setEventsSortType(4))
                }
            }}>
                Company

                { document.getElementById('events-company-sort')?.dataset.sort === 'true' ? (
                    <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                ) : null }

                { document.getElementById('events-company-sort')?.dataset.sortDesc === 'true' ? (
                    <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                ) : null }
            </div>,
            accessor: 'account.name',
            disableSortBy: true
        }, {
            Header: () => <div className={"cursor-pointer"} id={'events-name-sort'} data-sort={eventsSortDir === 0} data-sort-desc={eventsSortDir === 1} onClick={() => {
                columnsIds.filter(el => el !== 'events-name-sort').forEach(el => {
                    document.getElementById(el).dataset.sort = 'false';
                    document.getElementById(el).dataset.sortDesc = 'false';
                })
                const sort = document.getElementById('events-name-sort')
                let isDataSort = sort.dataset.sort === 'true';
                let isDataSortDesc = sort.dataset.sortDesc === 'true';

                if (isDataSort) {
                    sort.dataset.sort = 'false'
                    sort.dataset.sortDesc = 'true'
                    dispatch(setEventsSortDir(1))
                } else if (isDataSortDesc) {
                    sort.dataset.sortDesc = 'false'
                    dispatch(setEventsSortDir(-1))
                    dispatch(setEventsSortType(-1))
                } else {
                    sort.dataset.sort = 'true'
                    dispatch(setEventsSortDir(0))
                    dispatch(setEventsSortType(1))
                }
            }}>
                Event

                { document.getElementById('events-name-sort')?.dataset.sort === 'true' ? (
                    <img src={SortIcon} className={"dashboard-table-sort-icon reverse"} alt=""/>
                ) : null }

                { document.getElementById('events-name-sort')?.dataset.sortDesc === 'true' ? (
                    <img src={SortIcon} className={"dashboard-table-sort-icon"} alt=""/>
                ) : null }
            </div>,
            accessor: 'name',
            disableSortBy: true
        }, {
            id: 'edit',
            disableSortBy: true,
            accessor: 'id',
            Header: () => (
                <div className={"text-center"}>
                    Edit
                </div>
            ),
            Cell: ({cell: {value}}) => (
                <div className={"text-center"}>
                    <Link to={`/events/edit/${value}`}>
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M12.4271 1.75705C12.691 2.0209 12.691 2.43668 12.4271 2.70054L11.1158 4.00383L8.62118 1.50918L9.92447 0.197893C10.1883 -0.0659642 10.6041 -0.0659642 10.868 0.197893L12.4271 1.75705ZM0.625 12V9.50535L7.72513 2.4052L10.2198 4.89985L3.11964 12H0.625Z"
                                  fill="#8E8EA9"/>
                        </svg>
                    </Link>
                </div>
            )
        }, {
            id: 'delete',
            accessor: 'id',
            disableSortBy: true,
            Header: () => (
                <div className={"text-center"}>
                    Delete
                </div>
            ),
            Cell: ({cell: {value}}) => (
                <div className={"text-center"} onClick={() => {
                    setDeleteId(value)
                    setDeleteModalShow(true)
                }}>
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.57235 3.07438C1.44866 3.07438 1.35462 3.18554 1.37512 3.30753L2.83594 12H8.83594L10.2968 3.30753C10.3173 3.18554 10.2232 3.07438 10.0995 3.07438H1.57235ZM10.6359 0.991736C10.7464 0.991736 10.8359 1.08128 10.8359 1.19174V1.78347C10.8359 1.89393 10.7464 1.98347 10.6359 1.98347H1.03594C0.92548 1.98347 0.835938 1.89393 0.835938 1.78347V1.19174C0.835938 1.08128 0.925481 0.991736 1.03594 0.991736H3.69144C4.14144 0.991736 4.50694 0.446777 4.50694 0H7.16494C7.16494 0.446777 7.52994 0.991736 7.98044 0.991736H10.6359Z"
                            fill="#8E8EA9"/>
                    </svg>
                </div>
            )
        }
    ], []);

    if (myCampaing) {
        if (!columns.find(el => el.accessor === 'approvalStatus')) {
            columns.splice(2, 0, {
                Header: 'Status',
                accessor: 'approvalStatus',
                Cell: ({cell: {value}}) => {
                    return <>{value === 0 ? <span className={"text-orange"}>In Review</span> : value === 1 ?
                        <span className={"text-success"}>Approved</span> :
                        <span className={"text-danger"}>Paused</span>}</>
                }
            })
            columns[4].Cell = ({cell: {value}}) => (
                <div className={"text-center"}>
                    <Link to={`/events/edit/${value}?redirectTo=/campaigns`}>
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M12.4271 1.75705C12.691 2.0209 12.691 2.43668 12.4271 2.70054L11.1158 4.00383L8.62118 1.50918L9.92447 0.197893C10.1883 -0.0659642 10.6041 -0.0659642 10.868 0.197893L12.4271 1.75705ZM0.625 12V9.50535L7.72513 2.4052L10.2198 4.89985L3.11964 12H0.625Z"
                                  fill="#8E8EA9"/>
                        </svg>
                    </Link>
                </div>
            );
        }
    }

    useEffect(() => {
        setDataLoading(true)
        if (myCampaing === true) {
            accountsGet(userId).then(response => {
                if (response.data.success === true && response.data.data.length > 0) {
                    setAccounts(response.data.data.filter(el => el.isActive).map(el => {
                        return {name: el.name, value: el.id, id: el.id}
                    }))
                    setAccountId(response.data.data.filter(el => el.isActive)[0].id)
                    eventGetAllAccount(response.data.data.filter(el => el.isActive)[0].id, eventsSortDir, eventsSortType).then((eventsResponse) => {
                        if (eventsResponse.data.success === true && eventsResponse.data.data.items?.length > 0) {
                            setData(eventsResponse.data.data.items.reverse());
                        }
                    }).finally(() => {
                        setDataLoading(false);
                    })
                }
            })
        } else {
            console.log(eventsSortDir, eventsSortType)
            eventGetAll(page, eventsSortDir, eventsSortType).then(response => {
                if (response.data.success === true && response.data.data.items?.length > 0) {
                    setData(response.data.data.items);
                    dispatch(setEventPageCount(response.data.data.pagination.totalPages))
                }
            }).finally(() => {
                setDataLoading(false);
            })
        }
    }, [location, eventPerPage])

    useEffect(() => {
        if (myCampaing === true) {
            accountsGet(userId).then(response => {
                if (response.data.success === true && response.data.data.length > 0) {
                    setAccounts(response.data.data.filter(el => el.isActive).map(el => {
                        return {name: el.name, value: el.id, id: el.id}
                    }))
                    setAccountId(response.data.data.filter(el => el.isActive)[0].id)
                    eventGetAllAccount(response.data.data.filter(el => el.isActive)[0].id, eventsSortDir, eventsSortType).then((eventsResponse) => {
                        if (eventsResponse.data.success === true && eventsResponse.data.data.items?.length > 0) {
                            setData(eventsResponse.data.data.items.reverse());
                        }
                    }).finally(() => {
                        setDataLoading(false);
                    })
                }
            })
        } else {
            eventGetAll(page, eventsSortDir, eventsSortType).then(response => {
                if (response.data.success === true && response.data.data.items?.length > 0) {
                    setData(response.data.data.items);
                    dispatch(setEventPageCount(response.data.data.pagination.totalPages))
                }
            }).finally(() => {
                setDataLoading(false);
            })
        }
    }, [eventsSortDir, eventsSortType])

    useEffect(() => {
        if (accountId !== null) {
            eventGetAllAccount(accountId, eventsSortDir, eventsSortType).then((eventsResponse) => {
                if (eventsResponse.data.success === true) {
                    setData(eventsResponse.data.data.items.reverse());
                }
            })
        }
    }, [accountId])

    if (dataLoading) {
        return (
            <>
                <div className="dashboard-title">
                    Events
                </div>
                <div className="dashboard-card scheduler-empty">
                    <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Modal style={{zIndex: 40000}} centered={true} show={deleteModalShow}
                   onHide={() => setDeleteModalShow(false)}>
                <Modal.Body>
                    Are you sure to delete event
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        const deleteEventToast = toast.loading('Please wait...')
                        eventDelete(deleteId).then(response => {
                            toast.update(deleteEventToast, {
                                render: "Event deleted successfully",
                                type: "success",
                                isLoading: false,
                                autoClose: 5000
                            })
                        }).finally(() => {
                            setDeleteModalShow(false)
                            navigate('/events')
                        })
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="dashboard-title">
                Events
                <div className="dashboard-title-action">
                    <Button>
                        <Link to={`/events/create${!isAdmin && isAdvertiser ? '?redirectTo=/campaigns' : ''}`}>
                            Create Event
                        </Link>
                    </Button>
                </div>
            </div>
            <div className="dashboard-card">
                {accountId === null ? (
                    <DashboardEventsFilter/>
                ) : (
                    <DashboardEventsFilter accountId={accountId}/>
                )}
                {selectedEvents.length > 0 ? (
                    <Button onClick={async () => {
                        const deleteUserToast = toast.loading('Please wait...')
                        await Promise.all(
                            selectedEvents.map(async (el) => {
                                await eventDelete(el)
                            })
                        )
                        toast.update(deleteUserToast, {
                            render: "Events deleted successfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        })
                        navigate('/events')
                    }} variant={'secondary'}>Delete Selected</Button>
                ) : null}
                {myCampaing ? (
                    <DashboardSelect value={accountId} className={'mb-4 col-3'} options={accounts} onChange={(e) => {
                        setAccountId(parseInt(e.target.value))
                    }} />
                ) : null}
                <Table onSelect={(value) => {
                    dispatch(setSelectedEvents(value.map(el => {
                        return el.original.id
                    })))
                }} columns={columns} data={data}/>
            </div>
        </>
    );
};

export default DashboardEventsTable;