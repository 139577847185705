import * as Yup from "yup";

export const URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const EventFormSchema = Yup.object().shape({
    publishingDates: Yup.array().min(1, 'You need to select at least one date'),
    eventName: Yup.string().required('This filed is required').max(50, 'Max length 50 characters'),
    eventDescription: Yup.string().required('This filed is required'),
    eventAddress: Yup.string().required('This filed is required'),
    eventCity: Yup.string().required('This filed is required'),
    eventState: Yup.string().required('This filed is required'),
    eventZip: Yup.string().required('This filed is required'),
    eventActualTime: Yup.string().nullable(),
    eventCategory: Yup.array().min(1, 'You need to select at least one category'),
    eventPhoneNumber: Yup.string(),
    eventImage: Yup.array().min(1, 'You need to upload at least one image'),
    termsSubmit: Yup.boolean().oneOf([true], 'You need to check this checkbox')
});