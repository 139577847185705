import axios from "axios";
import {ACCESS_TOKEN, API_BASE_URL} from "../utills/constants/api";
import {toast} from "react-toastify";
import {updateToken} from "./UserService";
import moment from "moment";

axios.interceptors.request.use(config => {
    if (localStorage.getItem(ACCESS_TOKEN) !== null) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;

    if(error.response?.status === 401 || error.response?.status === 403) {
        const {token, refreshToken} = JSON.parse(localStorage.getItem(ACCESS_TOKEN));

        return updateToken().then(response => {
            if (response.data.success === true) {
                localStorage.setItem(ACCESS_TOKEN, JSON.stringify(response.data.data));

                axios.defaults.headers.Authorization = 'Bearer ' + response.data.data.token;

                return axios(originalRequest);
            } else {
                localStorage.removeItem(ACCESS_TOKEN);
                toast.error('Your account token has been expired, please log in again.', {delay: 3000});
                setTimeout(() => {
                    window.location.reload();
                }, 3000)
            }
        })
    }
    return Promise.reject(error);
});

export const accountCreate = (data) => {
    return axios.post(API_BASE_URL + `/gowzon-account/create`, {
        ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountUpdate = (data) => {
    return axios.post(API_BASE_URL + `/gowzon-account/update`, {
        ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountGetById = (id) => {
    return axios.post(API_BASE_URL + `/gowzon-account/${id}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountConnect = (userId, accountId) => {
    return axios.post(API_BASE_URL + `/gowzon-account/connect-user`, {
        userId, accountId
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountDisconnect = (userId, accountId) => {
    return axios.post(API_BASE_URL + `/gowzon-account/disconnect-user`, {
        userId, accountId
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'text/plain',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountsGetByName = async () => {
    return await axios.post(API_BASE_URL + `/gowzon-account/list`, {

    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountsUpdateEventDetails = async (accountId, data) => {
    return await axios.post(API_BASE_URL + `/gowzon-account/${accountId}/default-event`, {
        ...data
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountDelete = async (id) => {
    return await axios.post(API_BASE_URL + `/gowzon-account/${id}/delete`, {

    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountActivate = async (accountId) => {
    return await axios.post(API_BASE_URL + `/gowzon-account/activate/${accountId}`, {

    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountDeactivate = async (accountId) => {
    return await axios.post(API_BASE_URL + `/gowzon-account/deactivate/${accountId}`, {

    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountsSortBy = async (page, pageCount, userId, search = '', sortType = -1, sortDir = -1) => {
    if (page === undefined || page === null || page === 0) {
        page = 1
    }

    const data = {
        'search': search,
        'page': page,
        'pageSize': pageCount
    }

    if (sortDir !== -1) {
        data.sortDir = sortDir;
    } else {
        data.sortDir = 0;
    }

    if (sortType !== -1) {
        data.sortField = sortType;
    }

    return await axios.post(API_BASE_URL + `/gowzon-account/search`, data, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
            'credentials': 'same-origin',
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const accountGetAnalytic = (accountId) => {
    return axios.post(API_BASE_URL + `/custom-analytics/by-account`, {
            'from': "1900-01-01",
            'to': moment().add(1, 'd').format('YYYY-MM-DD'),
            "gowzonAccountId": accountId,
            "gowzonEventId": 0
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }})
}

export const accountGetAnalyticByDate = (accountId, date) => {
    return axios.post(API_BASE_URL + `/custom-analytics/by-account`, {
            'from': moment(date).format('YYYY-MM-DD'),
            'to': moment(date).add(1, 'd').format('YYYY-MM-DD'),
            "gowzonAccountId": accountId,
            "gowzonEventId": 0
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }})
}

export const accountGetAnalyticSummary = (accountId) => {
    return axios.post(API_BASE_URL + `/custom-analytics/summary`, {
            'from': moment(new Date('01-01-1990')).format('YYYY-MM-DD'),
            'to': moment(new Date()).format('YYYY-MM-DD'),
            "gowzonAccountId": accountId,
            "gowzonEventId": 0
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                'credentials': 'same-origin',
                'Access-Control-Allow-Origin': '*'
            }})
}