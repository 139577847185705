import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export const UserFormSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    phoneNumber: Yup.string().required('This field is required'),
    city: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    email: Yup.string().email('Invalid email').required('This field is required'),
    userName: Yup.string().required('This field is required'),
    zip: Yup.string().required('This field is required'),
    password: Yup.string()
        .min(6, 'Password must by longer than 6 symbols')
        .minUppercase(1, 'Password must contains at least one uppercase letter')
        .minLowercase(1, 'Password must contains at least one lowercase letter')
        .minNumbers(1, 'Password must contains at least one number')
        .minSymbols(1, 'Password must contains at least one special symbols')
        .required('This field is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('This field is required'),
});