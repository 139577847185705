import {UPDATE_DASHBOARD_DATA} from "../types";

export const setDashboardState = (payload) => {
    return {
        type: UPDATE_DASHBOARD_DATA,
        payload,
    };
};

export const setDashboardCategories = (categories) => (dispatch, getState) => {
    dispatch(setDashboardState({eventCategories: categories}));
};

export const setDashboardUser = (user) => (dispatch, getState) => {
    dispatch(setDashboardState({user: user}));
    dispatch(setDashboardState({userLoading: false}))
};

export const setSelectedUsers = (users) => (dispatch, getState) => {
    dispatch(setDashboardState({selectedUsers: users}));
};

export const setSelectedEvents = (events) => (dispatch, getState) => {
    dispatch(setDashboardState({selectedEvents: events}));
};

export const setAccountList = (accounts) => (dispatch, getState) => {
    dispatch(setDashboardState({accountsList: accounts}));
};

export const setAccountFilteredList = (accounts) => (dispatch, getState) => {
    dispatch(setDashboardState({accountsFilteredList: accounts}));
};

export const setUsersList = (accounts) => (dispatch, getState) => {
    dispatch(setDashboardState({usersList: accounts}));
};

export const setUsersFilteredList = (accounts) => (dispatch, getState) => {
    dispatch(setDashboardState({usersFilteredList: accounts}));
};

export const setAccountDetailsEditable = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({accountDetailsEditable: data}));
};

export const setEventPageCount = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({eventPageCount: data}));
};

export const setAccountPageCount = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({accountPageCount: data}));
};

export const setUserPageCount = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({userPageCount: data}));
};

export const setUserSearch = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({userSearch: data}));
};

export const setAccountSearch = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({accountSearch: data}));
};

export const setEventApprovalPageCount = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({eventApprovalPageCount: data}));
};

export const setEventApprovalType = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({eventApprovalType: data}));
};

export const setEventSearch = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({eventSearch: data}));
};

export const setSelectedAccount = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({selectedAccount: data}));
};

export const setAccountDeleteActionModal = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({accountDeleteActionModal: data}));
};

export const setAccountDeletedId = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({accountDeletedId: data}));
};

export const setEventsApprovalSort = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({eventsApprovalSort: data}));
};

export const setAccountTrigger = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({deleteAccountTrigger: data}));
};

export const setAccountsSortDir = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({accountsSortDir: data}));
};

export const setAccountsSortType = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({accountsSortType: data}));
};

export const setUsersSortDir = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({usersSortDir: data}));
};

export const setUsersSortType = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({usersSortType: data}));
};

export const setEventsSortDir = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({eventsSortDir: data}));
};

export const setEventsSortType = (data) => (dispatch, getState) => {
    dispatch(setDashboardState({eventsSortType: data}));
};