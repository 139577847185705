import React, {useEffect, useState} from 'react';
import NumberFormat from "react-number-format";

const DashboardFormatTextField = ({label, format, mask, placeholder, value, errors, help, touched, onChange, inputContainerClassName, ...props}) => {
    const [filedValue, setFiledValue] = useState(value);

    useEffect(() => {
        setFiledValue(value)
    }, [value])

    return (
        <div className={`dashboard-form-group ${props.className}`}>
            <label>
                {label}
                <div className={`dashboard-form-group-input ${inputContainerClassName}`}>
                    <NumberFormat
                        className={`dashboard-form-group-input`}
                        value={filedValue}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChange(e)
                            setFiledValue(e.target.value)
                        }}
                        format={format}
                        mask={mask}
                    />
                </div>
            </label>
            { help ? (
                <div className='dashboard-form-group-help'>
                    {help}
                </div>
            ) : null }

            {errors && touched ? (
                <div className="dashboard-form-group-errors">
                    {errors}
                </div>
            ) : null}
        </div>
    );
};

export default DashboardFormatTextField;