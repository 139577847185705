export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032];

export const eventCategories = [
    {
        value: 1,
        label: "Food & Drink"
    },
    {
        value: 2,
        label: "Business"
    },
    {
        value: 3,
        label: "For kids"
    },
    {
        value: 4,
        label: "Free events"
    },
    {
        value: 5,
        label: "Active"
    },
    {
        value: 6,
        label: "Live Music"
    },
    {
        value: 7,
        label: "Community"
    },
    {
        value: 8,
        label: "Shopping"
    },
    {
        value: 9,
        label: "Tickets"
    },
    {
        value: 10,
        label: "Services"
    }
];

export const states = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
]

export const roles = [
    {
        value: 'Administrator',
        label: 'Administrator'
    },
    {
        value: 'Advertiser',
        label: 'Advertiser'
    }
]