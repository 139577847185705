import React, {useEffect, useState} from 'react';
import Select from "react-select";
import "./DashboardMultiselect.scss";

const colourStyles = {
    input: styles => ({ ...styles,
        fontFamily: 'Lato',
        lineHeight: "20px",
        padding: "6px 7px",
        fontSize: "14px",
        boxShadow: "none",
        borderColor: '#DCDCE4',
        marginLeft: 0
    }),
    placeholder: styles => ({ ...styles,
        fontFamily: 'Lato',
        lineHeight: "20px",
        padding: "6px 7px",
        fontSize: "14px",
        borderColor: '#DCDCE4',
        marginLeft: 0
    }),
    multiValue: base => ({
        ...base,
        backgroundColor: '#F0F0FF',
        borderRadius: '4px',
        color: '#4945FF',
        fontWeight: 600,
        border: '1px solid #D9D8FF',
        hover: base => ({
            color: '#4945FF',
        })
    }),
    control: base => ({
        ...base,
        boxShadow: 'none',
        borderColor: '#DCDCE4',
    }),
    option: base => ({
        ...base,
        fontSize: '14px',
        color: '#32324D',
        fontWeight: 500
    }),
    menu: base => ({
        ...base,
        boxShadow: '0px 1px 4px rgba(33, 33, 52, 0.1)',
    }),
};

const DashboardMultiselect = ( { options, isClearable = true, name, onChange, maxOptions, value, placeholder, label, className, errors, touched, help, inputContainerClassName}) => {
    const [values, setValues] = useState(value);

    useEffect(() => {
        setValues(value)
    }, [value])

    return (
        <div className={`dashboard-form-group ${className}`}>
            <label>{label}</label>
            <Select
                isClearable={isClearable}
                placeholder={placeholder}
                options={options}
                isMulti
                onChange={(option, action) => { onChange(option, action); setValues(option)}}
                value={values}
                name={name}
                isOptionDisabled={() => values.length >= maxOptions}
                styles={colourStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: "4px"
                })}
                className={`dashboard-form-group-multiselect ${inputContainerClassName}`}/>

            { help ? (
                <div className='dashboard-form-group-help'>
                    {help}
                </div>
            ) : null }

            {errors && touched ? (
                <div className="dashboard-form-group-errors">
                    {errors}
                </div>
            ) : null}
        </div>
    );
};

export default DashboardMultiselect;