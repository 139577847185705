import React from 'react';
import {Link} from "react-router-dom";

const DashboardFormHeader = ({backTo, action, title = ''}) => {
    return (
        <div className="dashboard-form-header">
            <div className="dashboard-form-header-content">
                <div className="dashboard-form-header-content-link">
                    <Link to={backTo}>
                        Back
                    </Link>
                </div>
                <div className="dashboard-form-header-content-title">
                    {title}
                </div>
            </div>
            <div className="dashboard-form-header-action">
                {action}
            </div>
        </div>
    );
};

export default DashboardFormHeader;