import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import TextField from "../../../../components/Dashboard/DashbardForm/TextField/TextField";
import DashboardMultiselect
    from "../../../../components/Dashboard/DashbardForm/DashboardMultiselect/DashboardMultiselect";
import Button from "../../../../components/Button/Button";
import DashboardDatepicker from "../../../../components/Dashboard/DashbardForm/DashboardDatepicker/DashboardDatepicker";
import DashboardFormatTextField
    from "../../../../components/Dashboard/DashbardForm/DashboardFormatTextField/DashboardFormatTextField";
import DashboardFile from "../../../../components/Dashboard/DashbardForm/DashboardFile/DashboardFile";
import {eventCreate, eventGetAllAccount, eventImageUpload} from "../../../../services/EventService";
import moment from "moment";
import {states} from "../../../../utills/constants/general";
import {toast} from "react-toastify";
import {EventFormSchema} from "../../../../utills/constants/schemas/event";
import {useLocation, useNavigate} from "react-router";
import DashboardTextarea from "../../../../components/Dashboard/DashbardForm/DashboardTextarea/DashboardTextarea";
import DashboardTimepicker from "../../../../components/Dashboard/DashbardForm/DashboardTimepicker/DashboardTimepicker";
import DashboardSelect from "../../../../components/Dashboard/DashbardForm/DashboardSelect/DashboardSelect";
import {useSelector} from "react-redux";
import {accountsGet} from "../../../../services/UserService";
import {accountGetById} from "../../../../services/AccountService";
import {removeTags} from "../../../../utills/helpers/string";

const DashboardSchedulerCreateForm = () => {
    const [accounts, setAccounts] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [calendarLoading, setCalendarLoading] = useState(true);
    const [usedDates, setUsedDates] = useState([]);
    const [accountId, setAccountId] = useState(undefined);
    const [formValues, setFormValues] = useState({
        eventName: '',
        eventDescription: '',
        eventAddress: '',
        eventCity: '',
        eventZip: '',
        eventCategory: [],
        eventLink: '',
        eventState: '',
        eventPhoneNumber: '',
        eventLocationName: ''
    });

    const navigate = useNavigate();
    const { eventCategories } = useSelector((state) => state.dashboardState);
    const search = useLocation().search;
    const redirectTo = new URLSearchParams(search).get('redirectTo');

    const EventForm = useFormik({
        validationSchema: EventFormSchema,
        enableReinitialize: true,
        initialValues: {
            publishingDates: [],
            eventName: '',
            eventDescription: '',
            eventAddress: '',
            eventCity: '',
            eventState: states[0],
            eventActualTime: '',
            eventCategory: [],
            eventLink: '',
            eventPhoneNumber: '',
            eventImage: [],
            eventZip: '',
            termsSubmit: true,
            eventLocationName: '',
            accountId: accounts[0]
        },
        onSubmit: values => {
            const categories = values.eventCategory.map(el => {
                return el.value;
            })
            const publishingDates = values.publishingDates.map(el => {
                return moment(el).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            })
            const eventActualTime = moment(values.eventActualTime).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

            const id = toast.loading("Please wait...");

            eventImageUpload(values.eventImage[0].name, '', values.eventImage[0]).then(response => {
                if (response.data.success === true) {
                    eventCreate(values.eventName,
                        values.eventDescription,
                        values.eventAddress,
                        values.eventCity,
                        values.eventState,
                        values.eventPhoneNumber,
                        eventActualTime,
                        response.data.data.imageId,
                        categories,
                        '',
                        publishingDates,
                        values.eventZip,
                        values.eventLink,
                        values.accountId.id).then(res => {
                            if (res.data.success === true) {
                                toast.update(id, { render: "Event was created successfully", type: "success", isLoading: false, autoClose: 5000  })
                                navigate(`${redirectTo === undefined ? '/events' : redirectTo}`)
                            }
                        }).catch(err => {
                            toast.update(id, { render: "Event creating failed", type: "error", isLoading: false, autoClose: 5000 })
                        })
                }
            }).catch(err => {
                toast.update(id, { render: "Image uploading failed", type: "error", isLoading: false, autoClose: 5000 })
            })
        },
    });

    useEffect(() => {
        accountsGet().then(response => {
            if (response.data.success === true) {
                setAccounts(response.data.data.filter(el => el.isActive).map(el => {
                    return {name: el.name, value: el.id, id: el.id}
                }))
                eventGetAllAccount(response.data.data.filter(el => el.isActive)[0].id).then(eventResponse => {
                    if (eventResponse.data.success === true) {
                        const dates = eventResponse.data.data.items.map(el => el.publishingDates)
                        setUsedDates([].concat.apply([], dates))
                    }
                })
            }
        }).finally(() => {
            setDataLoading(false)
            setCalendarLoading(false)
        })
    }, []);

    useEffect(() => {
        if (accountId !== undefined) {
            setCalendarLoading(true)
            eventGetAllAccount(accountId.id).then(response => {
                if (response.data.success === true) {
                    const dates = response.data.data.items.map(el => el.publishingDates)
                    setUsedDates([].concat.apply([], dates))
                }
            }).finally(() => {
                setCalendarLoading(false)
            })
        }
    }, [accountId])

    const loadEventData = () => {
        accountGetById(EventForm.values.accountId.id).then(response => {
            if (response.data.success === true) {
                setFormValues((state) => {
                    return {...state, eventDescription: response.data.data.defaultEvent?.description ? response.data.data.defaultEvent?.description : ''}
                });
                setFormValues((state) => {
                    return {...state, eventAddress: response.data.data.defaultEvent?.address ? response.data.data.defaultEvent?.address : ''}
                });
                setFormValues((state) => {
                    return {...state, eventCity: response.data.data.defaultEvent?.city ? response.data.data.defaultEvent?.city : ''}
                });
                setFormValues((state) => {
                    return {...state, eventZip: response.data.data.defaultEvent?.zip ? response.data.data.defaultEvent?.zip : ''}
                });
                setFormValues((state) => {
                    return {...state, eventCategory: response.data.data.defaultEvent?.categoryIds ? response.data.data.defaultEvent?.categoryIds.map(el => {
                        return eventCategories.find(category => category.value === el)
                    }) : []}
                });
                setFormValues((state) => {
                    return {...state, eventPhoneNumber: response.data.data.defaultEvent?.eventPhoneNumber ? response.data.data.defaultEvent?.eventPhoneNumber : ''}
                });
                setFormValues((state) => {
                    return {...state, eventLink: response.data.data.defaultEvent?.eventUrl ? response.data.data.defaultEvent?.eventUrl : ''}
                });
                setFormValues((state) => {
                    return {...state, eventState: response.data.data.defaultEvent?.state ? response.data.data.defaultEvent?.state : ''}
                });
                setFormValues((state) => {
                    return {...state, eventLocationName: response.data.data.defaultEvent?.name ? response.data.data.defaultEvent?.name : ''}
                });
                EventForm.setFieldValue('eventDescription', response.data.data.defaultEvent?.description ? response.data.data.defaultEvent?.description : '');
                EventForm.setFieldValue('eventAddress', response.data.data.defaultEvent?.address ? response.data.data.defaultEvent?.address : '');
                EventForm.setFieldValue('eventCity', response.data.data.defaultEvent?.city ? response.data.data.defaultEvent?.city : '');
                EventForm.setFieldValue('eventZip', response.data.data.defaultEvent?.zip ? response.data.data.defaultEvent?.zip : '');
                EventForm.setFieldValue('eventPhoneNumber', response.data.data.defaultEvent?.eventPhoneNumber ? response.data.data.defaultEvent?.eventPhoneNumber : '');
                EventForm.setFieldValue('eventLink', response.data.data.defaultEvent?.eventUrl ? response.data.data.defaultEvent?.eventUrl : '');
                EventForm.setFieldValue('eventState', response.data.data.defaultEvent?.state ? response.data.data.defaultEvent?.state : '');
                EventForm.setFieldValue('eventLocationName', response.data.data.defaultEvent?.name ? response.data.data.defaultEvent?.name : '');
                EventForm.setFieldValue('eventCategory', response.data.data.defaultEvent?.categoryIds ? response.data.data.defaultEvent?.categoryIds.map(el => {
                    return eventCategories.find(category => category.value === el)
                }) : []);
            }
        })
    }

    if (dataLoading) {
        return (
            <div className="dashboard-form">
                <div className="scheduler-empty mt-5 pt-5 d-flex">
                    <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <form onSubmit={EventForm.submitForm} className={"form-container"}>
            <div className="row">
                <DashboardSelect
                    options={accounts}
                    onChange={(e) => {
                        EventForm.setFieldValue('accountId', accounts.find(el => {
                            return el.id === parseInt(e.target.value)
                        }))
                        setAccountId(accounts.find(el => {
                            return el.id === parseInt(e.target.value)
                        }))
                    }}
                    value={EventForm.initialValues.accountId}
                    errors={EventForm.errors.accountId}
                    touched={EventForm.touched.accountId}
                    label="Account"
                    className="col-6"/>
                <div className="col-6 d-flex align-items-end" style={{margin: '7px 0'}}>
                    <Button onClick={() => loadEventData()} variant={'secondary'}>
                        Load Event Details
                    </Button>
                </div>
            </div>
            <div className="row">
                { calendarLoading ? (
                    <div className="spinner-grow text-dark" role="status" style={{width: "2.5em", height: "2.5em"}}>

                    </div>
                ) : (
                    <DashboardDatepicker
                        disabled={usedDates}
                        onChange={(value) => EventForm.setFieldValue('publishingDates', value)}
                        value={EventForm.initialValues.publishingDates}
                        errors={EventForm.errors.publishingDates}
                        touched={EventForm.touched.publishingDates}
                        label="*Publishing Date(s)"
                        placeholder="Select Publishing Dates"
                        help={"You can apply an event to single or many days"}
                        inputContainerClassName={"icon icon-calendar"}
                        className="col-6"/>
                ) }

            </div>

            <div className="row">
                <DashboardTimepicker
                    onChange={(e) => {
                        EventForm.setFieldValue('eventActualTime', e)}
                    }
                    value={EventForm.initialValues.eventActualTime}
                    errors={EventForm.errors.eventActualTime}
                    touched={EventForm.touched.eventActualTime}
                    label="Actual Time of Event"
                    placeholder="Select Actual Time of Event"
                    help={"The selected time will be displayed as the main time for your event. Leave this selection empty if your event has no start time."}
                    inputContainerClassName={"icon icon-clock"}
                    className="col-6"/>
            </div>

            <div className="row">
                <TextField
                    onChange={(e) => EventForm.setFieldValue('eventName', e.target.value)}
                    value={formValues.eventName}
                    errors={EventForm.errors.eventName}
                    touched={EventForm.touched.eventName}
                    label={`Event Name`}
                    max={50}
                    placeholder="Event Name"
                    help={"This will be the headline of your event"}
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => EventForm.setFieldValue('eventLocationName', e.target.value)}
                    value={formValues.eventLocationName}
                    errors={EventForm.errors.eventLocationName}
                    touched={EventForm.touched.eventLocationName}
                    label={`Event Location Name`}
                    disabled={true}
                    placeholder="Event Location Name"
                    className="col-6"/>
            </div>
            <div className="row">
                <DashboardTextarea
                    onChange={(e) => {
                        const length = e.length - removeTags(e).length;
                        EventForm.setFieldValue('eventDescription', e.slice(0, 1000+length))
                    }}
                    value={formValues.eventDescription}
                    max={1000}
                    errors={EventForm.errors.eventDescription}
                    touched={EventForm.touched.eventDescription}
                    label="Enter Description"
                    placeholder="Enter Description"
                    help={"This is optional but users like to see additional information about your event"}
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => EventForm.setFieldValue('eventAddress', e.target.value)}
                    value={formValues.eventAddress}
                    errors={EventForm.errors.eventAddress}
                    touched={EventForm.touched.eventAddress}
                    label="Event Address"
                    placeholder="Event Address"
                    help="This is optional but users like to see additional information about your event"
                    className="col-6"/>
            </div>
            <div className="row">
                <TextField
                    onChange={(e) => EventForm.setFieldValue('eventCity', e.target.value)}
                    value={formValues.eventCity}
                    errors={EventForm.errors.eventCity}
                    touched={EventForm.touched.eventCity}
                    label="City"
                    placeholder="Fairfield"
                    className="col-2"/>

                <DashboardSelect
                    options={states}
                    onChange={(e) => EventForm.setFieldValue('eventState', e.target.value)}
                    value={formValues.eventState}
                    errors={EventForm.errors.eventState}
                    touched={EventForm.touched.eventState}
                    label="State"
                    className="col-2"/>

                <TextField
                    onChange={(e) => EventForm.setFieldValue('eventZip', e.target.value)}
                    value={formValues.eventZip}
                    errors={EventForm.errors.eventZip}
                    touched={EventForm.touched.eventZip}
                    label="Zip"
                    placeholder="Zip Code"
                    className="col-2"/>
            </div>
            <div className="row">
                <DashboardMultiselect
                    onChange={(option) => {
                        EventForm.setFieldValue('eventCategory', option)
                    }}
                    errors={EventForm.errors.eventCategory}
                    touched={EventForm.touched.eventCategory}
                    options={eventCategories}
                    value={formValues.eventCategory}
                    maxOptions={2}
                    placeholder="Select Category"
                    label="*Event Category (Max 2)"
                    name="eventTags"
                    inputContainerClassName={"icon icon-star"}
                    className="col-6"/>
            </div>

            <div className="row">
                <DashboardFile
                    value={EventForm.initialValues.eventImage}
                    onChange={(files) => EventForm.setFieldValue('eventImage', files)}
                    errors={EventForm.errors.eventImage}
                    touched={EventForm.touched.eventImage}
                    label={"Event Photo (Max 1)"}
                    help="Choose a photo or image representing your event. Use png or jpg format only. We recommend using a square image format (1:1) for the best event experience."
                    className="col-6"/>
            </div>

            <div className="row">
                <DashboardFormatTextField
                    onChange={(e) => EventForm.setFieldValue('eventPhoneNumber', e.target.value)}
                    value={formValues.eventPhoneNumber}
                    errors={EventForm.errors.eventPhoneNumber}
                    touched={EventForm.touched.eventPhoneNumber}
                    label="Event Phone Number"
                    placeholder="Event Phone Number"
                    help="Add a phone number if you want users to call you."
                    mask={"_"}
                    format={"(###)-###-####"}
                    className="col-6"/>
            </div>

            <div className="row">
                <TextField
                    onChange={(e) => EventForm.setFieldValue('eventLink', e.target.value)}
                    value={formValues.eventLink}
                    errors={EventForm.errors.eventLink}
                    touched={EventForm.touched.eventLink}
                    label="Event Link"
                    placeholder="Event Link"
                    help="Add an external link to your site, if you want to redirect users."
                    className="col-6"/>
            </div>

            <div className="row">
                <label className="dashboard-form-group dashboard-table-checkbox justify-content-start">
                    <input onChange={(e) => EventForm.setFieldValue('termsSubmit', e.target.checked)}
                           type="checkbox" checked={EventForm.values.termsSubmit}/>
                    <div className={"dashboard-table-checkbox-dec me-2"} />

                    <div className="dashboard-form-info">
                        By submitting this event, you agree that you own the rights to the image. See our <a target="_blank"
                        href="https://gowzon.com/gowzon-terms-and-conditions">Terms and Conditions</a>.
                    </div>

                </label>

                {EventForm.errors.termsSubmit && EventForm.touched.termsSubmit ? (
                    <div className="dashboard-form-group-errors mt-2">
                        {EventForm.errors.termsSubmit}
                    </div>
                ) : null}
            </div>

            <Button onClick={EventForm.handleSubmit}>Save Event</Button>
        </form>
    );
};

export default DashboardSchedulerCreateForm;