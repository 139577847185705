import React, {useState} from 'react';

const ToggleField = ({data, name, label, onChange, value}) => {
    const [fieldValue, setFieldValue] = useState(value);

    return (
        <div className={"dashboard-form-toggle-group"}>
            <div className="dashboard-form-toggle-group-label">
                {label}
            </div>
            <div className={"dashboard-form-toggle-group-container"}>
                {data.map((el, key) => {
                    return (<>
                        <input checked={el.value.toString() === fieldValue.toString()} onChange={(e) => {
                            setFieldValue(e.target.value);
                            onChange(e.target.value);
                        }} value={el.value} name={name} type="radio" id={`btnradio${key}`}/>
                        <label className="dashboard-form-toggle-group-button"
                               htmlFor={`btnradio${key}`}>{el.name}</label>
                    </>);
                })}
            </div>
        </div>
    );
};

export default ToggleField;