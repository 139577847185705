import React, {useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DashboardEventsEditForm from "./DashboardEventsEditForm/DashboardEventsEditForm";

const DashboardEventsCreate = () => {
    return (
        <>
            <div className="dashboard-title">
                Edit Event
            </div>
            <div className="dashboard-card">
                <DashboardEventsEditForm />
            </div>
        </>
    );
};

export default DashboardEventsCreate;